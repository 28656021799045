import { css } from '@emotion/css'
import Components from '@cloudmeet/web-components'

const { Colors } = Components.Styles

export const container = css`
  background: ${Colors.White};
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & .browser-icon {
    margin-bottom: 1rem;
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    max-height: 240px;
    margin: auto;
    font-size: 18px;
  }

  & h1 {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1a202c;
  }

  & h2 {
    text-align: center;
    font-size: 16px;
    color: #808996;
    margin-bottom: 3rem;
  }

  & button {
    max-width: 200px;
    background: #0072d6 !important;

    img {
      margin: 0.5rem;
    }
  }
`
