/**
 * App Routes
 */
export const HOME = '/'

export const PRESENTER = '/presenter'
export const ATTENDEE = '/attendee'
export const PRODUCER = '/producer'
export const ATTENDEE_NOTES = '/attendee/notes/:id'
export const AUDIO_VIDEO_SETTINGS = '/avsettings'
export const CHAT = '/chat'
export const PRESENTATION_ENDED = '/presentation-ended'
export const BOOTH = '/booth'
export const PANEL_GROUP = '/panel-group'
export const KEYNOTE = '/keynote'
export const ROUND_TABLE = '/round-table'
export const NOT_AUTHORIZED_URL = '/not-authorized'
export const MEETING_LEFT_URL = '/meeting-left'
/**
 * Meetings
 */
export const MEETINGS = '/meetings'

/**
 * Misc Routes
 */
export const NOT_FOUND = '/not-found'
export const BROWSER_NOT_SUPPORTED = '/browser-not-supported'
