import callApi from '../../common/api/apiRequest'

export type CreateBreakoutRoomRequest = {
  roomId: string
  roomKey: string
  participantKey: string
  roomName: string
  participants: CreateBreakoutRoomParticipant[]
}

export type CreateBreakoutRoomParticipant = {
  parentRoomParticipantKey: string
  name: string
}

export const createBreakoutRoom = async (request: CreateBreakoutRoomRequest) => {
  return callApi<string>('post', `rooms/createBreakoutRoom`, request)
}
