import { firestore, getParticipantsCollection } from '../common/lib/firebase'

export default (roomId: string, observer: (data: any) => void): Function => {
  return firestore
    .collection(getParticipantsCollection(roomId))
    .orderBy('name')
    .onSnapshot((result: any) => {
      const response: any[] = []
      result.docs.forEach((x: any) => {
        const data = x.data()
        if (!data.kickedOut && !data.leftRoom) {
          response.push(x.data())
        }
      })
      observer(response)
    })
}
