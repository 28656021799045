import { firestore, firebase, getQuestionsCollection } from '../common/lib/firebase'

export default (roomId: string, data: any): Promise<void> => {
  const newQuestion = {
    ...data,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  }

  return firestore
    .collection(getQuestionsCollection(roomId))
    .doc(data.docId)
    .set({
      ...newQuestion,
    })
}
