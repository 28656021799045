import { firestore, getQuestionsCollection } from '../common/lib/firebase'
import { Question } from './models'

export default async (roomId: string, questions: Question[]): Promise<any> => {
  const collectionName = getQuestionsCollection(roomId)
  const batch = firestore.batch()
  questions.forEach((q) => {
    const ref = firestore.collection(collectionName).doc(q.docId)
    batch.update(ref, {
      status: 'DELETED',
    })
  })
  await batch.commit()
}
