import callApi from '../../common/api/apiRequest'

type BreakoutRoomsSessionCommandRequest = {
  roomId: string
  roomKey: string
  participantKey: string
}

export const closeBreakoutRoomsSession = async (request: BreakoutRoomsSessionCommandRequest) => {
  return callApi<void>('put', `rooms/CloseBreakoutRooms`, request)
}
