import React from 'react'
import Components from '@cloudmeet/web-components'
import * as Style from './style'
import { Role } from '@cloudmeet/web-core/tenants/models'
import { changeHost } from '@cloudmeet/web-core/rooms/api'
import useCommandRequest from '../common/hooks/useCommandRequest'
import { ParticipantDto } from '@cloudmeet/web-core/rooms/models'
import useAuthState from '../common/hooks/useAuthState'
import useCommandRequestV2 from '../common/hooks/useCommandRequestV2'
import { kickOutParticipant } from '@cloudmeet/web-core/rooms/kickOutParticipant'
import useSharedState, { SharedStateKeys } from '../common/hooks/useSharedState'

const { UserAvatar } = Components.UI.User
const { Colors } = Components.Styles
const { Button, PopConfirm } = Components.UI

type Props = {
  participants: [] | any
}

export default (props: Props) => {
  const presentersAndModerators = props.participants?.filter(
    (x: ParticipantDto) => x.role === Role.Moderator || x.role === Role.Presenter,
  )
  const [, setTriggerMuteAll] = useSharedState(SharedStateKeys.TriggerMuteAll, false)
  const [auth] = useAuthState()
  const attendees = props.participants?.filter((x: ParticipantDto) => x.role === Role.Attendee)

  const onMuteAll = () => {
    setTriggerMuteAll(true)
  }

  return (
    props.participants && (
      <div className={Style.container}>
        {auth.isHost && (
          <div className={'mb-16'}>
            <Button className={'mute-btn'} label={'Mute all'} type={'normal'} onClick={() => onMuteAll()} />
          </div>
        )}

        {presentersAndModerators && presentersAndModerators.length > 0 && <div className={Style.title}>Presenters</div>}

        {presentersAndModerators &&
          presentersAndModerators.map((participant: ParticipantDto) => (
            <DisplayParticipant participant={participant} />
          ))}

        {attendees && attendees.length > 0 && <div className={Style.title}>Attendees({attendees.length})</div>}

        {attendees &&
          attendees.length > 0 &&
          attendees.map((item: ParticipantDto) => <DisplayParticipant participant={item} />)}
      </div>
    )
  )
}

type DisplayParticipantProps = {
  participant: ParticipantDto
}

const DisplayParticipant = ({ participant }: DisplayParticipantProps) => {
  let containerRoleStyle: string = ''
  let roleTagStyle: string = ''
  let avatarColor: string = ''

  const [auth] = useAuthState()
  const { isLoading, sendCommandRequest } = useCommandRequest()
  const isNotParticipantSameAsInAuth = auth.participantKey !== participant.participantKey

  const showChangeHost =
    isNotParticipantSameAsInAuth && auth.canChangeHost && !participant.isHost && participant.role !== Role.Attendee

  const showKickOutParticipant = isNotParticipantSameAsInAuth && auth.canKickOut && !participant.isHost

  const [sendKickOutParticipant, isLoadingKickOutParticipant] = useCommandRequestV2()

  const onKickOutParticipant = async (participant: ParticipantDto) => {
    await sendKickOutParticipant(() =>
      kickOutParticipant({
        roomId: auth.roomId,
        participantKey: auth.participantKey,
        roomKey: auth.roomKey,
        participantKeyToKickOut: participant.participantKey,
      }),
    )
  }

  switch (participant.role) {
    case Role.Presenter:
      containerRoleStyle = 'blueContainer'
      roleTagStyle = 'blueText'
      avatarColor = Colors.PrimaryColor
      break
    case Role.Moderator:
      containerRoleStyle = 'cyanContainer'
      roleTagStyle = 'cyanText'
      avatarColor = Colors.Cyan
      break
    default:
      avatarColor = Colors.Gray7
  }

  return (
    <div className={`${Style.participantContainer} ${containerRoleStyle ? (Style as any)[containerRoleStyle] : ''}`}>
      <UserAvatar name={participant.name} color={avatarColor} />
      <div className={Style.participantDetails}>
        <div className={Style.participantDetailsName}>
          {participant.isHost ? `${participant.name}(host)` : `${participant.name}`}
          {auth.participantKey === participant.participantKey ? `(me)` : ``}
        </div>
        {participant.role !== Role.Attendee && (
          <div className={`${Style.participantDetailsRole}  ${(Style as any)[roleTagStyle]}`}>
            {participant.roleCustomLabel}
          </div>
        )}
      </div>
      <div className={Style.participantContainerActions}>
        {showChangeHost && (
          <div className={Style.changeHostContainer}>
            <Button
              loading={isLoading}
              disabled={isLoading}
              label={'Make Host'}
              type={'normal'}
              onClick={async () => {
                await sendCommandRequest(() =>
                  changeHost({
                    roomId: auth.roomId,
                    roomKey: auth.roomKey,
                    participantKey: auth.participantKey,
                    newHostParticipantKey: participant.participantKey,
                  }),
                )
              }}
            />
          </div>
        )}
        {showKickOutParticipant && (
          <PopConfirm
            title={`Are you sure you want to kick out ${participant.name}?`}
            onConfirm={() => onKickOutParticipant(participant)}
          >
            <Button
              loading={isLoadingKickOutParticipant}
              disabled={isLoadingKickOutParticipant}
              label={'Kick out'}
              type="danger"
              onClick={() => {}}
            />
          </PopConfirm>
        )}
      </div>
    </div>
  )
}
