import React from 'react'
import { Button } from 'antd'
import Components from '@cloudmeet/web-components'

import * as Style from './style'
import { Role } from '@cloudmeet/web-core/tenants/models'
import useSharedState, { SharedStateKeys } from '../common/hooks/useSharedState'
import useSharePersistedState from '../common/hooks/useSharedPersistedState'

const { UserAvatar } = Components.UI.User
const { Colors } = Components.Styles

export type ChatItem = {
  id: string
  name: string
  time: string
  message: string
  role: Role
  participantId: string
  roomId: string
  showAddMessageToNotes: boolean
  roleCustomLabel: string
}

const Component = (props: ChatItem) => {
  const { message, name, role, time, id, roleCustomLabel } = props

  const [, setChatMessageToNote] = useSharedState<{ id: string; message: string } | null>(
    SharedStateKeys.ChatMessageToNote,
    null,
  )
  const [chatMessagesOnNotes] = useSharePersistedState<string[]>(SharedStateKeys.ChatMessagesOnNotes, [])

  let containerRoleStyle: string = ''
  let roleTagStyle: string = ''
  let avatarColor: string = ''

  switch (role) {
    case Role.Presenter:
      containerRoleStyle = 'blueContainer'
      roleTagStyle = 'blueText'
      avatarColor = Colors.PrimaryColor
      break
    case Role.Moderator:
      containerRoleStyle = 'cyanContainer'
      roleTagStyle = 'cyanText'
      avatarColor = Colors.Cyan
      break
    default:
      avatarColor = Colors.Gray7
  }

  const onAddToNotesClick = () => {
    setChatMessageToNote({ id, message })
  }

  return (
    <div className={`${Style.chatItemContainer} ${containerRoleStyle ? (Style as any)[containerRoleStyle] : ''}`}>
      <UserAvatar name={name} color={avatarColor} />
      <div className={Style.chatItemContent}>
        <div className={Style.chatItemNameDate}>
          <div className={Style.chatItemName}>{name}</div>
          <div className={Style.chatItemDate}> &bull; {time}</div>
        </div>
        <div className={Style.chatItemMessage}>{message}</div>
        {role !== Role.Attendee && (
          <div className={`${Style.chatItemRole} ${(Style as any)[roleTagStyle]}`}>{roleCustomLabel}</div>
        )}
        {!chatMessagesOnNotes.includes(id) && props.showAddMessageToNotes && (
          <Button className={Style.chatItemAddToNoteBtn} type="link" onClick={onAddToNotesClick}>
            Add to notes
          </Button>
        )}
      </div>
    </div>
  )
}

export default Component
