import { Form, Formik, FormikProps } from 'formik'

import Components from '@cloudmeet/web-components'
import { AskQuestionEmptySchema, AskQuestionValidationSchema } from './schemas'
import { Radio, Space } from 'antd'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { ASK_QUESTION_INFORMATION_STEP } from './constants'

import * as Style from './style'
import useCommandRequest from '../common/hooks/useCommandRequest'
import useSharedState, { SharedStateKeys } from '../common/hooks/useSharedState'
import addQuestion from '@cloudmeet/web-core/questions/addQuestion'
import useAuthState from '../common/hooks/useAuthState'

const { Modal, Textarea, Button } = Components.UI

const AskQuestionForm = (parentProps: any) => {
  const [auth] = useAuthState()
  const [, setLastQuestionId] = useSharedState<string | null>(SharedStateKeys.LastQuestionId, null)
  const { isLoading, sendCommandRequest } = useCommandRequest()

  const [visible, setVisible] = useState(false)
  const [initialValues] = useState(AskQuestionEmptySchema)

  useEffect(() => {
    setVisible(parentProps.visible)
  }, [parentProps.visible])

  const handleOnResetForm = (formProps: FormikProps<any>) => {
    formProps.resetForm()
    parentProps.notifyVisibleChanges('')
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={AskQuestionValidationSchema}
        enableReinitialize={true}
        onSubmit={async (values, actions) => {
          const request = {
            content: values.questionText,
            type: values.questionType,
            userId: auth.participantKey,
            roomId: auth.roomId,
            username: auth.participantName,
            docId: uuid(),
            status: 'CREATED',
          }
          const result = await sendCommandRequest(() => addQuestion(auth.roomId, request))

          if (result.completed) {
            setLastQuestionId(request.docId)
            actions.resetForm()
            parentProps.notifyVisibleChanges(ASK_QUESTION_INFORMATION_STEP)
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <Modal
            visible={visible}
            title="Ask a question"
            closable={false}
            closeIcon={false}
            wrapClassName={Style.modalWrapper}
            footer={[
              <Button
                className={Style.footerActionBtn}
                type="secondary"
                label="Cancel"
                key="back"
                onClick={handleOnResetForm.bind(null, props)}
              />,
              <Button
                className={Style.footerActionBtn}
                key="submit"
                label="Continue"
                type="normal"
                loading={isLoading}
                onClick={props.submitForm}
              />,
            ]}
          >
            <Form>
              <Textarea name="questionText" rows={5} placeholder={'Enter question'} {...props} />
              <div />
              <div>
                <Radio.Group name="questionType" onChange={props.handleChange} value={props.values.questionType}>
                  <Space direction="vertical">
                    <>
                      <Radio name="questionType" value="TEXT">
                        Post question to moderator
                      </Radio>
                      <Radio name="questionType" value="LIVE">
                        Enter queue for Live question
                      </Radio>
                    </>
                  </Space>
                </Radio.Group>
              </div>
            </Form>
          </Modal>
        )}
      </Formik>
    </div>
  )
}

export default AskQuestionForm
