import { useEffect, useState } from 'react'
import Components from '@cloudmeet/web-components'
const { error } = Components.Utils.Notifications

type SendQueryRequestOptions = {
  showErrors: boolean
}

export default <T>(
  initialState?: T,
  func?: () => Promise<T>,
  opts?: SendQueryRequestOptions,
): [T | null, boolean, (func: () => Promise<T>, opts?: SendQueryRequestOptions) => Promise<T | null>, () => void] => {
  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState<T | null>(initialState ?? null)

  const sendQueryRequest = async (
    overrideFunc?: () => Promise<T>,
    overrideOpts?: SendQueryRequestOptions,
  ): Promise<T | null> => {
    const funcToExecute = overrideFunc ?? func
    if (!funcToExecute) throw new Error('No function specified to execute')

    try {
      setIsLoading(true)
      const result = await funcToExecute()
      setData(result)
      setIsLoading(false)
      return result
    } catch (err) {
      setIsLoading(false)

      const options = overrideOpts ?? opts
      const displayErrors = !options || options.showErrors

      if (displayErrors) {
        await error('An error occurred while getting the data.')
      }
      setData(null)
      return null
    }
  }

  const reset = () => {
    setData(initialState ?? null)
  }

  useEffect(() => {
    if (func) {
      sendQueryRequest(func, opts).then(() => {})
    }
  }, [])

  return [data, isLoading, sendQueryRequest, reset]
}
