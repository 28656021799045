import { css } from '@emotion/css'

export const container = css`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-content: flex-start;
  overflow: hidden;
`

export const viewerContainer = css`
  display: flex;
  height: 100vh;
`

export const hiddenView = css`
  display: none;
`

export const experienceArea = css`
  width: 100%;
`

export const videoConferenceContainer = css`
  position: relative;

  & .right-buttons-wrap {
    position: absolute !important;
    bottom: 8px;
    right: 8px;
    display: flex;
    @media screen and (max-width: 768px) {
      top: 16px;
      left: 8px;
      bottom: auto;
      right: auto;
      width: auto !important;
    }

    button {
      margin-right: 1rem !important;
    }

    button:last-child {
      margin-right: 0 !important;
    }
  }

  & .total-unread-messages {
    position: absolute;
    top: 20px;
    left: 50%;
  }

  & .breakout-room-session-running-wrap {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 15%;

    & h1 {
      color: white;
      font-weight: bold;
    }

    & h2 {
      color: #d3d9e0;
      font-weight: bold;
    }

    & h3 {
      color: #808996;
      font-weight: bold;
    }
  }
`

export const endPresentationButton = (canStartBreakoutRooms: boolean) => css`
  max-width: 120px;
  position: absolute !important;
  bottom: 8px;
  right: 8px;
  @media screen and (max-width: 768px) {
    top: 16px;
    left: ${canStartBreakoutRooms ? '50px' : '10px'};
    bottom: auto;
    right: auto;
    width: auto !important;
  }
`

export const verifyParticipantButton = css`
  max-width: 120px;
  position: absolute !important;
  bottom: 8px;
  left: 8px;
`

export const verifyModalFooter = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & .ant-btn {
    margin-right: 0.5rem !important;
    width: auto !important;
    height: auto !important;
  }
`

export const verifyModalFooterBtnContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const roomNameWrap = css`
  position: absolute !important;
  top: 1rem;
  left: 1rem;

  @media screen and (max-width: 768px) {
    top: 4rem;
  }

  & .room-name {
    padding: 0.25rem;
    background: #92949c;
    color: white;
  }

  & .breakout-room-countdown {
    padding: 0.25rem;
    background: #cf1322;
    color: white;
  }
`

export const chatNotification = css`
  margin-left: 0.5rem;
`

export const iframeContainer = css`
  width: 100%;
  height: 100vh;
  border: none;
  margin: 0;
  border: 0px;
`

export const viewerTabs = css`
  background: #f7f8fa;

  & .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  & .ant-tabs-nav {
    padding: 1rem 2rem 0;
  }

  & .ant-tabs-content-holder {
    padding: 1rem 2rem;
    background: white;
  }
`

export const debugging = css`
  width: 100%;
  height: 100px;
  background-color: forestgreen;
  color: white;
  flex: 1;
  padding: 15px;
`

export const modalContent = css`
  & .ant-modal {
    width: calc(100vw - 16px) !important;
    padding: 0 10px;
    @media screen and (min-width: 769px) {
      width: 720px !important;
    }
  }

  & .ant-modal-body {
    width: 100%;
  }
`

export const startBreakoutRooms = css`
  background: #151517 !important;
  color: white;
  max-width: 36px;
  position: absolute !important;
  bottom: 16px;
  left: 8px;
  @media screen and (max-width: 768px) {
    top: 16px;
    left: 8px;
    bottom: auto;
  }
`

export const stopBreakoutRooms = css`
  max-width: 120px;
  // position: absolute !important;
  top: 8px;
  right: 8px;
`

export const recordingIndicatorWrap = css`
  display: inline-block;
  margin-left: 1rem;
`

export const recordingIndicator = css`
  max-width: 110px;
  border-radius: 32px;
  padding: 2px 5px !important;
  color: white !important;
  background: #d62c34;
  display: flex;
  justify-content: center;
  align-items: center;
  & i {
    margin-right: 5px;
    font-size: 16px;
  }
`
