import React, { MouseEventHandler } from 'react'
import Moment from 'moment'
import { useSelector } from 'react-redux'
import { Button, Popover } from 'antd'

import * as Style from './style'
import {
  generateTemporaryAttendeeRoomCredentials,
  generateVerifyAttendeeRoomCredentials,
  GenerateVerifyAttendeeRoomCredentialsResponse,
} from '@cloudmeet/web-core/rooms/api'
import updateQuestion from '@cloudmeet/web-core/questions/updateQuestion'
import useCommandRequest from '../common/hooks/useCommandRequest'
import useAuthState from '../common/hooks/useAuthState'

export type QuestionQueueItemDto = {
  type: 'TEXT' | 'LIVE'
  createdAt: any
  status?: 'CREATED' | 'REQUESTED_VERIFICATION' | 'VERIFIED' | 'ACCEPTED' | 'CONSUMED' | 'DELETED'
  content: string
  roomId: string
  userId: string
  docId: string
  username: string
  role: string
  liveRoomCredentials?: {
    roomId: string
    roomKey: string
    participantKey: string
  }
}

type Props = {
  onVerifiedAttendee?: (credentials: any) => void
  onNextQuestion?: (...params: any) => void
}

const Component = (props: QuestionQueueItemDto & Props) => {
  const [auth] = useAuthState()

  const {
    isLoading: isGenerateVerifyAttendeeRoomCredentials,
    sendCommandRequest: sendGenerateVerifyAttendeeRoomCredentials,
  } = useCommandRequest()
  const { isLoading: isVerifyTextLoading, sendCommandRequest: sendVerifyTextQuestion } = useCommandRequest()
  const {
    isLoading: isGenerateTemporaryAttendeeRoomCredentials,
    sendCommandRequest: sendGenerateTemporaryAttendeeRoomCredentials,
  } = useCommandRequest()
  const { isLoading: isDeleteQuestionLoading, sendCommandRequest: sendDeleteQuestionRequest } = useCommandRequest()

  const { docId, roomId, type, status, content, username, createdAt, onNextQuestion } = props
  let actions = <></>

  const requestDeleteQuestion = async () => {
    await sendDeleteQuestionRequest(() =>
      updateQuestion(props.docId, auth.roomId, {
        status: 'DELETED',
        roomCredentials: null,
      }),
    )
  }

  switch (status) {
    case 'CREATED':
      actions = (
        <div className={Style.actions}>
          <Button className={Style.deleteBtn} loading={isDeleteQuestionLoading} disabled={isDeleteQuestionLoading}>
            <i className="ri-close-line" onClick={requestDeleteQuestion} />
          </Button>

          <Button
            className={Style.verifyBtn}
            loading={isGenerateVerifyAttendeeRoomCredentials || isVerifyTextLoading}
            disabled={isGenerateVerifyAttendeeRoomCredentials || isVerifyTextLoading}
            onClick={async () => {
              if (props.onVerifiedAttendee && type === 'LIVE') {
                const result = await sendGenerateVerifyAttendeeRoomCredentials<GenerateVerifyAttendeeRoomCredentialsResponse>(
                  () =>
                    generateVerifyAttendeeRoomCredentials({
                      roomKey: auth.roomKey,
                      participantKey: auth.participantKey,
                      roomId: auth.roomId,
                      hostParticipantName: auth.participantName,
                      attendeeParticipantKey: props.userId,
                      attendeeParticipantName: props.username,
                      questionId: props.docId,
                    }),
                )

                if (!result.data) return

                if (result.completed) {
                  props.onVerifiedAttendee({
                    questionId: props.docId,
                    name: result.data.name,
                    jwt: result.data.host.jwt,
                    alternativeName: result.data.alternativeName,
                  })
                }
              } else if (type === 'TEXT') {
                await sendVerifyTextQuestion(() =>
                  updateQuestion(docId, roomId, {
                    status: `ACCEPTED`,
                  }),
                )
              }
            }}
          >
            {type === 'TEXT' ? 'Accept' : 'Verify'}
          </Button>
        </div>
      )
      break
    case 'VERIFIED':
      actions = (
        <div className={Style.actions}>
          <Button className={Style.deleteBtn} loading={isDeleteQuestionLoading} disabled={isDeleteQuestionLoading}>
            <i className="ri-close-line" onClick={requestDeleteQuestion} />
          </Button>
          {onNextQuestion ? (
            <Button
              className={Style.nextBtn}
              onClick={() => {
                onNextQuestion(props)
              }}
            >
              {props.type === 'TEXT' ? 'Next' : 'Go Live'}
            </Button>
          ) : null}
        </div>
      )
      break
    case 'ACCEPTED':
      actions = (
        <Button
          className={Style.closeBtn}
          onClick={requestDeleteQuestion}
          loading={isDeleteQuestionLoading}
          disabled={isDeleteQuestionLoading}
        >
          Close
        </Button>
      )
      break
  }

  return (
    <div className={`${Style.queueItemContainer}`}>
      <div className={Style.queueItemContent}>
        <div className={Style.queueItemTitle}>
          <div className={Style.queueItemTitleContainer}>
            <div className={Style.queueItemIcon}>
              {type === 'LIVE' ? <i className="ri-vidicon-line" /> : <i className="ri-message-2-line" />}
            </div>
            <div className={Style.queueItemName}>
              {username}
              {createdAt && <div className={Style.queueItemDate}>{Moment(createdAt.seconds * 1000).fromNow()}</div>}
            </div>
          </div>
          <div className={Style.queueItemActions}>{actions}</div>
        </div>
        <div className={Style.queueItemText}>{content}</div>
      </div>
    </div>
  )
}

const QuestionMorePopover = (props: {
  onEnter: MouseEventHandler<HTMLDivElement>
  onDecline: MouseEventHandler<HTMLDivElement>
}) => {
  return (
    <Popover
      className={Style.morePopover}
      placement="bottomLeft"
      trigger="click"
      defaultVisible={false}
      content={
        <div className={Style.morePopoverContent}>
          <div className={Style.morePopoverItem} onClick={props.onEnter}>
            <i className="ri-checkbox-circle-line" />
            <p>Enter in the room</p>
          </div>
          <div className={Style.morePopoverItem} onClick={props.onDecline}>
            <i className="ri-close-circle-line" />
            <p>Decline request</p>
          </div>
        </div>
      }
    >
      <Button className={Style.popoverButton} type={'link'} icon={<i className="ri-more-2-fill" />} />
    </Popover>
  )
}

export default Component
