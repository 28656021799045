import { css } from '@emotion/css'

export const container = css``

export const bgContainer = css`
  background-color: #1a202c;
  width: 100%;
  background-image: url(/assets/images/control-room-preparation-bg.svg);
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  -webkit-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 1;
  z-index: 1;
  text-align: center;
`
export const title = css`
  font-size: 38px;
  line-height: 46px;
  font-weight: 600;
  color: white;
  margin-bottom: 24px;
`
export const description = css`
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  color: white;
`
