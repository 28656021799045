import useSharedState from './useSharedState'
import { GetDetailsForParticipantResponse } from '@cloudmeet/web-core/rooms/api'
import { Role } from '@cloudmeet/web-core/tenants/models'
import { RoomRecordingOption, RoomType } from '@cloudmeet/web-core/rooms/models'

const AuthEmpty: GetDetailsForParticipantResponse = {
  participantKey: '',
  participantName: '',
  role: Role.Attendee,
  videoConferenceJwt: '',
  isHost: false,
  isHostForParentRoom: false,
  kickedOut: false,
  isPublicParticipant: true,
  roomId: '',
  presentersChatRoomId: '',
  roomName: '',
  roomKey: '',
  videoConferenceRoomId: '',
  theme: {
    backgroundColor: '',
    primaryColor: '',
  },
  roomType: RoomType.Booth,
  recordingOption: RoomRecordingOption.NoRecording,
  requiresRecording: false,
  isBreakoutRoom: false,
  presentationStarted: false,
  presentationEnded: false,
  isOnlyViewer: false,
  canStartLivestream: false,
  canRecordOnVideoConference: false,
  canAddNotes: false,
  canManageQuestions: false,
  canStartPresentation: false,
  canEndPresentation: false,
  canStartBreakoutRooms: false,
  canKickOut: false,
  canListBreakoutRooms: false,
  canCloseBreakoutRooms: false,
  canChangeHost: false,
  hasStartLivestream: false,
  roleCustomLabel: 'Attendee',
}

const useAuthState = (
  initialState?: GetDetailsForParticipantResponse,
): [GetDetailsForParticipantResponse, (auth: GetDetailsForParticipantResponse) => void] => {
  const [auth, setAuth] = useSharedState<GetDetailsForParticipantResponse>('auth', initialState ?? AuthEmpty, true)

  return [auth, setAuth]
}

export default useAuthState
