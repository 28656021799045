import { css } from '@emotion/css'

export const createBreakoutRoomsFormWrap = css`
  width: 200px;
  margin: 0 auto;

  & .ant-input {
    border: none !important;
    font-size: 56px;
    text-align: center;
    height: auto !important;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
`
