import { Question } from './models'
import { firestore, getQuestionsCollection } from '../common/lib/firebase'

export const getUserLastQuestion = (roomId: string, userId: string): Promise<Question | null> => {
  return new Promise((resolve, reject) => {
    firestore
      .collection(getQuestionsCollection(roomId))
      .where('userId', '==', userId)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()
      .then((x): any => {
        let result = null
        x.forEach((x) => {
          result = x.data()
        })
        resolve(result)
      })
      .catch((err) => reject(err))
  })
}
