import * as Yup from 'yup'

export type AskQuestionForm = {
  questionText: string
  questionType: any
}

export const AskQuestionEmptySchema: AskQuestionForm = {
  questionText: '',
  questionType: 'TEXT',
}

export const AskQuestionValidationSchema = Yup.object().shape({
  questionText: Yup.string()
    .required('Question is required')
    .min(8, 'Question is invalid (Minimum 8 characters required)'),
})
