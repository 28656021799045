import React, { useEffect, useState } from 'react'
import Components from '@cloudmeet/web-components'
import * as Style from './style'
import CreateBreakoutRooms from './createBreakoutRooms'
import AssignParticipantsToBreakoutRooms from './assignParticipantsToBreakoutRooms'
import { useSelector } from 'react-redux'
import listenToAllParticipantChanges from '@cloudmeet/web-core/rooms/listenToAllParticipantChanges'
import { ParticipantDto } from '@cloudmeet/web-core/rooms/models'
import { Role } from '@cloudmeet/web-core/tenants/models'
import BreakoutRoom from '@cloudmeet/web-core/rooms/breakoutRooms/breakoutRoom'
import generateBreakoutRooms from '@cloudmeet/web-core/rooms/breakoutRooms/generateBreakoutRooms'
import {
  CreateBreakoutRoomRequest,
  createBreakoutRoom,
  CreateBreakoutRoomParticipant,
} from '@cloudmeet/web-core/rooms/breakoutRooms/createBreakoutRooms'
import useCommandRequestV2 from '../common/hooks/useCommandRequestV2'
import { startBreakoutRoomsSession } from '@cloudmeet/web-core/rooms/breakoutRooms/startBreakoutRoomsSession'
import useAuthState from '../common/hooks/useAuthState'

const { error, success, info } = Components.Utils.Notifications
const { Button, Modal } = Components.UI

type ComponentPros = {
  onCanceled?: () => void
  onStartBreakout?: () => void
}

export default (props: ComponentPros) => {
  /** ------------------------------------ **
   Entity states
   ** ------------------------------------ **/
  const [auth] = useAuthState()
  const [wizardStep, setWizardStep] = useState(WizardStep.CREATE_BREAKOUT_ROOMS)
  const [participants, setParticipants] = useState<ParticipantDto[]>([])
  const [totalRooms, setTotalRooms] = useState<number>(0)
  const [breakoutRooms, setBreakoutRooms] = useState<BreakoutRoom[]>([])
  const [breakoutSessionLength, setBreakoutSessionLength] = useState<number>(0)
  const [sendCommandRequest, isLoading] = useCommandRequestV2()

  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/
  useEffect(() => {
    listenToAllParticipantChanges(auth.roomId, (data: any) => {
      setParticipants(data)
    })
  }, [])

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/
  const onTotalRoomsChanged = (value: number) => {
    setTotalRooms(value)
  }

  const onCreateRooms = async () => {
    const isValid = await validateTotalNumberOfRooms(totalRooms, participants, auth.participantKey)
    if (!isValid) {
      return
    }

    const result = generateBreakoutRooms(totalRooms, participants, auth.participantKey)
    if (result.length === 0) {
      await error(`Could not create breakout rooms.`)
      return
    }

    setBreakoutRooms(result)
    setWizardStep(WizardStep.ASSIGN_PARTICIPANTS_TO_BREAKOUT_ROOMS)
  }

  const onRemoveParticipant = (roomId: string, participantKey: string) => {
    const filteredBreakoutRooms: BreakoutRoom[] = []
    for (let i = 0; i < breakoutRooms.length; i++) {
      const breakoutRoom = breakoutRooms[i]
      if (breakoutRoom.id === roomId) {
        breakoutRoom.participants = breakoutRoom.participants.filter((x) => x.participantKey !== participantKey)
      }
      filteredBreakoutRooms.push(breakoutRoom)
    }
    setBreakoutRooms(filteredBreakoutRooms)
  }

  const onBreakoutSessionLengthChanged = (breakoutSessionLength: number) => {
    setBreakoutSessionLength(breakoutSessionLength)
  }

  const onStartBreakout = async () => {
    const roomsWithEmptyParticipants = breakoutRooms.filter((x) => x.participants.length === 0)
    if (roomsWithEmptyParticipants.length > 0) {
      roomsWithEmptyParticipants.forEach((x: BreakoutRoom) => {
        error(`${x.name} should have at least one participant.`)
      })
      return
    }

    for (let i = 0; i < breakoutRooms.length; i++) {
      const breakoutRoom = breakoutRooms[i]

      const request: CreateBreakoutRoomRequest = {
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        participantKey: auth.participantKey,
        roomName: breakoutRoom.name,
        participants: breakoutRoom.participants.map<CreateBreakoutRoomParticipant>((p) => {
          return {
            name: p.name,
            parentRoomParticipantKey: p.participantKey,
          }
        }),
      }

      const result = await sendCommandRequest(() => createBreakoutRoom(request))
      if (result.completed) {
        success(`${request.roomName} created`)
      }
    }

    info('Starting breakout rooms session...')

    const startBreakoutRoomResult = await sendCommandRequest(() =>
      startBreakoutRoomsSession({
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        participantKey: auth.participantKey,
        breakoutSessionLength: breakoutSessionLength,
      }),
    )

    if (startBreakoutRoomResult.completed && props.onStartBreakout) {
      props.onStartBreakout()
    }
  }

  const onMoveParticipantToRoom = (currentRoomId: string, toRoomId: string, participant: ParticipantDto) => {
    const filteredBreakoutRooms: BreakoutRoom[] = []

    for (let i = 0; i < breakoutRooms.length; i++) {
      const breakoutRoom = breakoutRooms[i]
      if (breakoutRoom.id === currentRoomId) {
        breakoutRoom.participants = breakoutRoom.participants.filter(
          (x) => x.participantKey !== participant.participantKey,
        )
      }
      if (breakoutRoom.id === toRoomId) {
        breakoutRoom.participants.push(participant)
      }
      filteredBreakoutRooms.push(breakoutRoom)
    }
    setBreakoutRooms(filteredBreakoutRooms)
  }

  return (
    <>
      <Modal
        wrapClassName={Style.modalWrapper}
        title={`Breakout Session`}
        visible={true}
        footer={
          <div className={Style.modalFooter}>
            <div style={{ width: '50%' }} />
            <div className={Style.modalFooterBtnContainer}>
              <Button
                key="button"
                type="light"
                label={'Cancel'}
                loading={false}
                disabled={false}
                onClick={() => {
                  if (props.onCanceled) {
                    props.onCanceled()
                  }
                }}
              />

              {wizardStep === WizardStep.CREATE_BREAKOUT_ROOMS && (
                <Button
                  key="button"
                  type="normal"
                  label={'Create rooms'}
                  loading={false}
                  disabled={totalRooms < 1}
                  onClick={onCreateRooms}
                />
              )}

              {wizardStep === WizardStep.ASSIGN_PARTICIPANTS_TO_BREAKOUT_ROOMS && (
                <Button
                  key="button"
                  type="normal"
                  label={'Start Breakout'}
                  loading={isLoading}
                  disabled={breakoutSessionLength < 1 || isLoading}
                  onClick={onStartBreakout}
                />
              )}
            </div>
          </div>
        }
      >
        {wizardStep === WizardStep.CREATE_BREAKOUT_ROOMS && (
          <CreateBreakoutRooms participants={participants} onTotalRoomsChanged={onTotalRoomsChanged} />
        )}

        {wizardStep === WizardStep.ASSIGN_PARTICIPANTS_TO_BREAKOUT_ROOMS && breakoutRooms.length > 0 && (
          <AssignParticipantsToBreakoutRooms
            breakoutRooms={breakoutRooms}
            onRemoveParticipant={onRemoveParticipant}
            onBreakoutSessionLengthChanged={onBreakoutSessionLengthChanged}
            onMoveParticipantToRoom={onMoveParticipantToRoom}
          />
        )}
      </Modal>
    </>
  )
}

const validateTotalNumberOfRooms = async (
  totalRooms: number,
  participants: ParticipantDto[],
  participantKey: string,
): Promise<boolean> => {
  const totalPresenters = participants.filter((x) => x.role === Role.Presenter && x.participantKey !== participantKey)
    .length
  const totalAttendees = participants.filter((x) => x.role === Role.Attendee).length
  const totalPossibleRooms = totalAttendees + totalPresenters

  if (totalPossibleRooms == 0) {
    await error(`Not enough presenters and attendees.`)
    return false
  }

  if (totalRooms > totalPossibleRooms) {
    await error(`Number of rooms(${totalRooms}) cannot be greater than total participants (${totalPossibleRooms}).`)
    return false
  }

  return true
}

const WizardStep = {
  CREATE_BREAKOUT_ROOMS: 'CREATE_BREAKOUT_ROOMS',
  ASSIGN_PARTICIPANTS_TO_BREAKOUT_ROOMS: 'ASSIGN_PARTICIPANTS_TO_BREAKOUT_ROOMS',
}
