import callApi from '../../common/api/apiRequest'

type BreakoutRoomCommandRequest = {
  roomId: string
  roomKey: string
  participantKey: string
  breakoutRoomId: string
  participantName: string
}

export type JoinBreakoutRoomResponse = {
  roomId: string
  roomKey: string
  participantKey: string
}

export const joinBreakoutRoom = async (request: BreakoutRoomCommandRequest) => {
  return callApi<JoinBreakoutRoomResponse>('post', `rooms/JoinBreakoutRoom`, request)
}
