import React from 'react'
import * as Style from './style'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as Routes from '../../config/routes'
import NotFound from '../NotFound'
import BrowserNotSupported from '../BrowserNotSupported'
import AttendeeNotes from '../../../notesViewer'
import Attendee from '../../../attendee'
import PresentationEnded from '../PresentationEnded'
import Booth from '../../../booth'
import PanelGroup from '../../../panelGroup'
import Keynote from '../../../keynote'
import RoundTable from '../../../roundTable'
import NotAuthorized from '../NotAuthorized/components'
import Home from '../../../home'
import AVSettings from '../../../avSettings'
import ChatRoom from '../../../chat/ChatRoom'
import MeetingLeft from '../MeetingLeft'

type IProps = {}

const AppContainer: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={Style.bodyContainer}>
      <div className={Style.body}>
        <Switch>
          <Route key={Routes.NOT_FOUND} path={Routes.NOT_FOUND} component={NotFound} />
          <Route
            key={Routes.BROWSER_NOT_SUPPORTED}
            path={Routes.BROWSER_NOT_SUPPORTED}
            component={BrowserNotSupported}
          />
          <Route key={Routes.ATTENDEE_NOTES} path={Routes.ATTENDEE_NOTES} component={AttendeeNotes} />
          <Route key={Routes.ATTENDEE} path={Routes.ATTENDEE} component={Attendee} />
          <Route key={Routes.PRESENTATION_ENDED} path={Routes.PRESENTATION_ENDED} component={PresentationEnded} />
          <Route key={Routes.BOOTH} path={Routes.BOOTH} component={Booth} />
          <Route key={Routes.PANEL_GROUP} path={Routes.PANEL_GROUP} component={PanelGroup} />
          <Route key={Routes.KEYNOTE} path={Routes.KEYNOTE} component={Keynote} />
          <Route key={Routes.ROUND_TABLE} path={Routes.ROUND_TABLE} component={RoundTable} />
          <Route key={Routes.NOT_AUTHORIZED_URL} path={Routes.NOT_AUTHORIZED_URL} component={NotAuthorized} />
          <Route key={Routes.HOME} path={Routes.HOME} component={Home} exact />
          <Route key={Routes.AUDIO_VIDEO_SETTINGS} path={Routes.AUDIO_VIDEO_SETTINGS} component={AVSettings} exact />
          <Route key={Routes.CHAT} path={Routes.CHAT} component={ChatRoom} exact />
          <Route key={Routes.MEETING_LEFT_URL} path={Routes.MEETING_LEFT_URL} component={MeetingLeft} exact />
          <Redirect to={Routes.NOT_FOUND} />
        </Switch>
      </div>
    </div>
  )
}

export default AppContainer
