import useSharedState from './useSharedState'

export type Media = {
  videoDevice: string
  audioInputDevice: string
  audioOutputDevice: string
  videoEnabled: boolean
  audioEnabled: boolean
}

const Empty: Media = {
  videoDevice: '',
  audioInputDevice: '',
  audioOutputDevice: '',
  videoEnabled: false,
  audioEnabled: false,
}

const useMediaState = (initialState?: Media): [Media, (data: Media) => void] => {
  const [data, setData] = useSharedState<Media>('media', initialState ?? Empty, true)

  return [data, setData]
}

export default useMediaState
