export type AvailableMediaDevicesType = {
  videoDevices: MediaDeviceInfo[]
  audioInputDevices: MediaDeviceInfo[]
  audioOutputDevices: MediaDeviceInfo[]
}

export type AVMediaContraints = {
  video?: MediaTrackConstraints
  audio?: MediaTrackConstraints
}

export const getMediaDevices = (): Promise<MediaDeviceInfo[]> => navigator.mediaDevices.enumerateDevices()

// Desktop browser support: Chrome (72+), Firefox (66+), Safari (12.2+)
// Mobile browser support: Not supported, use SDK https://www.twilio.com/docs/video/screen-capture-chrome
// Typescript v4.1.3 does not yet support navigator.mediaDevices.getDisplayMedia
// https://github.com/microsoft/TypeScript/issues/33232
// @ts-ignore
export const getDisplayMediaStream = (constraints: any): Promise<MediaStream> =>
  // @ts-ignore
  navigator.mediaDevices.getDisplayMedia(constraints)

export const getVideoDevices = async (): Promise<MediaDeviceInfo[]> => {
  try {
    return (await getMediaDevices()).filter((d) => d.kind === 'videoinput')
  } catch (e) {
    return []
  }
}

export const getAudioInputDevices = async (): Promise<MediaDeviceInfo[]> => {
  try {
    return (await getMediaDevices()).filter((d) => d.kind === 'audioinput')
  } catch (e) {
    return []
  }
}

export const getAudioOutputDevices = async (): Promise<MediaDeviceInfo[]> => {
  try {
    return (await getMediaDevices()).filter((d) => d.kind === 'audiooutput')
  } catch (e) {
    return []
  }
}

export const getMediaStream = async (constraints: MediaStreamConstraints): Promise<MediaStream | null> => {
  try {
    return await navigator.mediaDevices.getUserMedia(constraints)
  } catch (e) {
    console.error(e)
    return null
  }
}

export const getFirstOrDefaultDevice = (devices: MediaDeviceInfo[]): any => {
  return devices && devices.length > 0 ? devices[0].label : null
}

export const requirePermissionForUserMedia = async (constraints: AVMediaContraints) =>
  await getMediaStream({
    video:
      constraints.video !== null
        ? {
            ...constraints.video,
          }
        : true,
    audio:
      constraints.audio !== null
        ? {
            ...constraints.audio,
          }
        : true,
  })

export const getAvailableMediaDevices = async (): Promise<AvailableMediaDevicesType> => {
  const audioInputDevices = await getAudioInputDevices()
  const audioOutputDevices = await getAudioOutputDevices()
  const videoDevices = await getVideoDevices()

  return {
    videoDevices,
    audioInputDevices,
    audioOutputDevices,
  }
}
