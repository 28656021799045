import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { Router } from 'react-router'
import 'remixicon/fonts/remixicon.css'
import Store, { history, persistedStore } from './store'
import AppLoading from './common/components/AppLoading'
import './App.css'
import AppContainer from './common/components/AppContainer'

function App() {
  return (
    <div className="App">
      <Provider store={Store}>
        <PersistGate loading={<AppLoading />} persistor={persistedStore}>
          <Router history={history}>
            <AppContainer />
          </Router>
        </PersistGate>
      </Provider>
    </div>
  )
}

export default App
