import callApi from '../../common/api/apiRequest'

type StartBreakoutRoomsSessionRequest = {
  roomId: string
  roomKey: string
  participantKey: string
  breakoutSessionLength: number
}

export const startBreakoutRoomsSession = async (request: StartBreakoutRoomsSessionRequest) => {
  return callApi<void>('put', `rooms/startBreakoutRoomsSession`, request)
}
