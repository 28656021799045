import React from 'react'
import * as Style from './style'
import Components from '@cloudmeet/web-components'
import { Role } from '@cloudmeet/web-core/tenants/models'
import { Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import BreakoutRoom from '@cloudmeet/web-core/rooms/breakoutRooms/breakoutRoom'
import { ParticipantDto } from '@cloudmeet/web-core/rooms/models'
import './ovverides.css'

const { UserAvatar } = Components.UI.User
const { Colors } = Components.Styles
const { Input, Popover, Tooltip } = Components.UI
const { usePageWidth } = Components.Hooks

type ComponentPros = {
  breakoutRooms: BreakoutRoom[]
  onRemoveParticipant?: (roomId: string, participantKey: string) => void
  onBreakoutSessionLengthChanged?: (breakoutSessionLength: number) => void
  onMoveParticipantToRoom?: (currentRoomId: string, toRoomId: string, participant: ParticipantDto) => void
}

export default (props: ComponentPros) => {
  const width = usePageWidth()
  const isMobile = width <= 768
  return (
    <div className={Style.wrapper}>
      <Formik
        enableReinitialize
        initialValues={{
          breakoutSessionLength: null,
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={(values: FormikValues) => {}}
      >
        {(form) => (
          <div style={isMobile ? {} : { width: '200px' }}>
            <Input
              autoFocus={true}
              label={'Breakout session length'}
              addonAfter={<span>Minutes</span>}
              type={'number'}
              name={'breakoutSessionLength'}
              onChangeFunction={(newValue: string) => {
                const breakoutSessionLength = newValue ? parseInt(newValue, 10) : 0
                if (props.onBreakoutSessionLengthChanged) {
                  props.onBreakoutSessionLengthChanged(breakoutSessionLength)
                }
              }}
              placeholder={'ex. 10'}
              {...form}
            />
          </div>
        )}
      </Formik>

      <div className={isMobile ? 'breakout-rooms' : 'breakout-rooms row'}>
        {props.breakoutRooms.map((breakoutRoom) => (
          <div key={breakoutRoom.id} className={isMobile ? 'w-100' : 'col-6'}>
            <div className={'breakout-room'}>
              <div className={'title-wrap'}>
                <div className={'title'}>{breakoutRoom.name}</div>
                <div className={'total-participants'}>{breakoutRoom.participants.length}</div>
              </div>
              <div className={'content-wrap'}>
                <div className={'participants-list'}>
                  {breakoutRoom.participants.map((participant) => (
                    <div id={participant.participantKey} className={'participant-list-item'}>
                      <div className={'mr-8'}>
                        <UserAvatar name={participant.name} color={Colors.PrimaryColor} />
                      </div>

                      <div className={'mr-8'}>{participant.name}</div>

                      {participant.role === Role.Presenter && (
                        <div className={'mr-8 presenter-list-item'}> {participant.roleCustomLabel}</div>
                      )}

                      {/*{participant.role === Role.Attendee && (*/}
                      {/*  <div*/}
                      {/*    className={'delete-wrap mr-8'}*/}
                      {/*    onClick={() => {*/}
                      {/*      if (props.onRemoveParticipant) {*/}
                      {/*        props.onRemoveParticipant(breakoutRoom.id, participant.participantKey)*/}
                      {/*      }*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <i className="ri-close-line"></i>*/}
                      {/*  </div>*/}
                      {/*)}*/}

                      {props.breakoutRooms.length > 1 && (
                        <div style={{ marginLeft: 'auto' }}>
                          <Popover
                            content={
                              <PossibleRoomsToMoveParticipant
                                breakoutRooms={props.breakoutRooms}
                                currentRoomId={breakoutRoom.id}
                                onRoomSelected={(roomIdSelected) => {
                                  if (props.onMoveParticipantToRoom) {
                                    props.onMoveParticipantToRoom(breakoutRoom.id, roomIdSelected, participant)
                                  }
                                }}
                              />
                            }
                            trigger={'click'}
                            title={`Move '${participant.name}' from '${breakoutRoom.name}' to: `}
                            placement={'bottom'}
                          >
                            <div className={'move-btn'}>
                              <Tooltip title={'Move to another room'}>
                                <i className="ri-arrow-right-line"></i>
                              </Tooltip>
                            </div>
                          </Popover>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

type PossibleRoomsToMoveParticipantProps = {
  breakoutRooms: BreakoutRoom[]
  currentRoomId: string
  onRoomSelected?: (roomIdSelected: string) => void
}

const PossibleRoomsToMoveParticipant = (props: PossibleRoomsToMoveParticipantProps) => {
  const possibleRooms = props.breakoutRooms.filter((x) => x.id !== props.currentRoomId)

  return (
    <div className={'possible-rooms-to-move-participant-wrap'}>
      {possibleRooms.map((x) => (
        <div
          className={'possible-room-to-move-participant'}
          onClick={() => {
            if (props.onRoomSelected) {
              props.onRoomSelected(x.id)
            }
          }}
        >
          {x.name}
        </div>
      ))}
    </div>
  )
}
