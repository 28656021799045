import React, { useCallback } from 'react'
import Components from '@cloudmeet/web-components'

import * as Style from './style'
import { useHistory } from 'react-router-dom'

const { Button } = Components.UI

const NotFound: React.FC = () => {
  const history = useHistory()

  const onBack = useCallback(() => {
    history.goBack()
  }, [])

  return (
    <div className={Style.container}>
      <div className="content">
        <img src="/assets/images/cloudmeet.svg" />
        <h1>Not Found</h1>
        <div className="go-back">
          <Button
            isCircle
            className="go-back-btn"
            icon={<i className="ri-arrow-left-s-line"></i>}
            label=""
            type="light"
            onClick={onBack}
          />
          <span>Go back</span>
        </div>
      </div>
    </div>
  )
}

export default NotFound
