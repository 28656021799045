import { firestore, getQuestionsCollection } from '../common/lib/firebase'

export default (roomId: string, questionId: string, observer: (data: any) => void): void => {
  const collectionName = getQuestionsCollection(roomId)
  firestore
    .collection(collectionName)
    .doc(questionId)
    .onSnapshot((doc: any) => {
      const data = doc.data()
      if (data) {
        observer(data)
      }
    })
}
