import Axios from 'axios'
import Config from '../config'

export const Methods = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
}

const resolveUrl = (url: string): string => {
  return url.includes('http') ? url : `${Config.Api.baseURL}/${url}`
}
/**
 * Http Client
 */
export default async (method: any, url: string, body?: any, authToken?: string | null, headers?: any | null) => {
  if (url.search(/\{\w+\}/) >= 0) {
    throw new TypeError(`HttpClient invalid argument: url=${url} contains a placeholder`)
  }
  const isFileUpload = body instanceof FormData

  if (!headers)
    headers = {
      'Content-Type': isFileUpload ? 'multipart/form-data' : 'application/json',
    }

  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`
  }
  return Axios({
    method,
    url: resolveUrl(url),
    headers,
    data: body || undefined,
  })
}
