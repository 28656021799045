import { firestore, getParticipantsCollection } from '../common/lib/firebase'

export default (
  roomId: string,
  participantKey: string,
  observer: (data: any, previousData?: any) => void,
): Function => {
  const collectionName = getParticipantsCollection(roomId)

  return firestore
    .collection(collectionName)
    .doc(participantKey)
    .onSnapshot((docSnapshot: any) => {
      const data = docSnapshot.data()
      if (data) {
        const previousData = localStorage.getItem('previousParticipantData')
        observer(data, previousData ? JSON.parse(previousData) : null)
        localStorage.setItem('previousParticipantData', JSON.stringify(data))
      }
    })
}
