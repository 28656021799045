export const openFullscreen = (elem: any): Promise<any> => {
  if (elem.requestFullscreen) {
    return elem.requestFullscreen()
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    return elem.webkitRequestFullscreen()
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    return elem.msRequestFullscreen()
  }
  return Promise.resolve()
}

export const closeFullscreen = (): Promise<any> => {
  if (document.exitFullscreen) {
    return document.exitFullscreen()
  } else {
    // @ts-ignore
    if (document.webkitExitFullscreen) {
      // @ts-ignore
      return document.webkitExitFullscreen()
    }
    // @ts-ignore
    else if (document.mozCancelFullScreen) {
      // @ts-ignore
      return document.mozCancelFullScreen()
    }
    // @ts-ignore
    else if (document.msExitFullscreen) {
      // @ts-ignore
      return document.msExitFullscreen()
    }
  }
  return Promise.resolve()
}

export const isFullScreen = (): boolean => {
  return (
    // @ts-ignore
    ((document.fullscreenElement && document.fullscreenElement !== null) ||
      // @ts-ignore
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      // @ts-ignore
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      // @ts-ignore
      (document.msFullscreenElement && document.msFullscreenElement !== null)) !== undefined
  )
}

export const addFullscreenEventListener = (handler: () => void) => {
  if (!document.addEventListener) return

  document.addEventListener('fullscreenchange', handler, false)
  document.addEventListener('mozfullscreenchange', handler, false)
  document.addEventListener('MSFullscreenChange', handler, false)
  document.addEventListener('webkitfullscreenchange', handler, false)
}
