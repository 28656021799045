import React, { useEffect, useState } from 'react'
import Components from '@cloudmeet/web-components'
import * as Style from './style'

import useSharedState, { SharedStateKeys } from '../common/hooks/useSharedState'
import useCommandRequest from '../common/hooks/useCommandRequest'
import updateQuestion from '@cloudmeet/web-core/questions/updateQuestion'
import listenToAllQuestionChanges from '@cloudmeet/web-core/questions/listenToAllQuestionChanges'

const { Button, PopConfirm } = Components.UI

type AskQuestionProps = {
  roomId: string
  onShowStatusBar: (show: boolean) => void
}

export default (props: AskQuestionProps) => {
  const { roomId, onShowStatusBar } = props
  const [lastQuestionId] = useSharedState<string | null>(SharedStateKeys.LastQuestionId, null)
  const [questions, setQuestions] = useState([])

  const verifiedQuestions = questions?.filter((q: any) => q.status === 'VERIFIED')
  const verifiedLastQuestion = verifiedQuestions?.find((q: any) => q.docId === lastQuestionId)

  const queuedQuestions = questions?.filter((q: any) => q.status === 'CREATED')
  const queuedLastQuestion: any = queuedQuestions?.find((q: any) => q.docId === lastQuestionId)
  const { isLoading, sendCommandRequest } = useCommandRequest()

  const onCancelConfirm = async () => {
    if (!lastQuestionId) return

    await sendCommandRequest(() =>
      updateQuestion(lastQuestionId, roomId, {
        status: 'DELETED',
      }),
    )
  }

  useEffect(() => {
    listenToAllQuestionChanges(roomId, (data: any) => {
      setQuestions(data)
    })
  }, [])

  useEffect(() => {
    onShowStatusBar(verifiedLastQuestion || queuedLastQuestion)
  }, [verifiedLastQuestion, queuedLastQuestion])

  return (
    <>
      {(verifiedLastQuestion || queuedLastQuestion) && (
        <div className={verifiedLastQuestion ? Style.askQuestionAcceptedStatus : Style.askQuestionVerifyingStatus}>
          <div className={Style.askQuestionStatusBarText}>
            <div>
              <i className="ri-time-line ask-question-status-bar-timing" />
            </div>
            <div>
              {queuedLastQuestion && queuedLastQuestion.type === 'LIVE' && (
                <>
                  <span>Waiting for the moderator to check before you can join the panel to ask the question</span>
                  <br />
                  <span> {queuedQuestions?.length} people in the queue </span>
                </>
              )}
              {queuedLastQuestion && queuedLastQuestion.type === 'TEXT' && (
                <>
                  <span>Waiting for the moderator to validate your question</span>
                </>
              )}
              {verifiedLastQuestion && <span> You are #{verifiedQuestions?.length} in the queue </span>}
            </div>
          </div>
          <div className={Style.askQuestionStatusBarButton}>
            <PopConfirm title={'Cancel request?'} onConfirm={onCancelConfirm}>
              <Button className={Style.cancelQuestionButton} label={'Cancel'} type="transparent" onClick={() => {}} />
            </PopConfirm>
          </div>
        </div>
      )}
    </>
  )
}
