import { Card, Badge } from 'antd'
import * as Style from './style'
import React from 'react'
import Components from '@cloudmeet/web-components'

import { BreakoutRoomParticipant } from '@cloudmeet/web-core/rooms/breakoutRooms/getBreakoutRoomsSession'

const { Avatar } = Components.UI

type ComponentPros = {
  participant: BreakoutRoomParticipant
}

export default (props: ComponentPros) => {
  return (
    <Badge count={<i className="ri-slideshow-line"></i>} offset={[0, 30]}>
      <Avatar innerText={props.participant.name} size={Style.avatarDefaults.size} style={Style.avatarDefaults.style} />
    </Badge>
  )
}
