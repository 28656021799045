import { css } from '@emotion/css'

export const body = css`
  background: #1a202c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @media screen and (max-width: 768px) {
    height: auto;
  }

  hr {
    margin-top: 24px;
    border-color: #535c68;
    margin-bottom: 24px;
  }
`
export const mainContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 720px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center !important;
    margin: 20px 0px;
  }
`

export const videoWrap = css`
  background: #2d3748;
  border-radius: 16px;
  width: 100%;
  @media screen and (max-width: 425px) {
    width: 348px !important;
  }
  @media screen and (max-width: 320px) {
    width: 287px !important;
  }
`

export const videoContainer = css`
  border-radius: 16px;
  background-color: #2d3748;
  margin-bottom: 16px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 382px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const toggleDevicesWrap = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem 1rem 1rem;

  label {
    color: white !important;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
`

export const formContainer = css`
  margin-left: 24px;
  background: #2d3748;
  padding: 2rem;
  border-radius: 16px;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    margin-top: 10px;
  }

  .ant-form-item-label > label {
    color: white;
  }
`

export const recordingOptionWrap = css`
  width: 100%;
`

export const recordingOptionItem = css`
  display: flex;
  align-items: center;
  width: 100%;
`

export const switchRecordingContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-form-item {
    margin-bottom: 0 !important;
  }
`

export const actionBtn = css`
  width: 100%;
`

export const switchRow = css`
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin: auto auto 16px;
  align-items: center;
  & .ant-form-item {
    margin-bottom: 0 !important;
    display: block;
  }
  & .ant-select-selector {
    width: 270px !important;
    @media screen and (max-width: 768px) {
      width: 384px !important;
    }

    @media screen and (max-width: 425px) {
      width: 284px !important;
    }
    @media screen and (max-width: 320px) {
      width: 200px !important;
    }
  }
`

export const switchTitle = css`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
  width: 100%;
  align-items: center;

  i {
    color: #b1b7c0;
  }
`

export const audioSelectorContainer = css`
  display: flex;
  flex-direction: column;
`
