import { firestore, getQuestionsCollection } from '../common/lib/firebase'

export default (roomId: string, observer: (data: any) => void) => {
  const collectionName = getQuestionsCollection(roomId)
  return firestore
    .collection(collectionName)
    .orderBy('createdAt')
    .onSnapshot((result: any) => {
      const data: any[] = []
      result.docs.forEach((x: any) => {
        data.push(x.data())
      })
      observer(data)
    })
}
