import { css } from '@emotion/css'
import Components from '@cloudmeet/web-components'

const { Colors } = Components.Styles

export const container = css`
  background: ${Colors.White};
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    max-height: 240px;
    margin: auto;
    font-size: 18px;
  }
  & h1 {
    display: flex;
    font-weight: 600;
    color: #00b7f1;
  }
  & .go-back {
    display: flex;
    justify-content: center;
    align-items: center;
    & span {
      color: ${Colors.Gray8};
      margin-left: 10px;
      font-size: 16px;
      font-weight: 500;
    }
    & .go-back-btn {
      color: #00b7f1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
