import { firebase, firestore, getP2AMessagesCollection } from '../common/lib/firebase'

export default (roomId: string, data: any): Promise<string> => {
  const message = {
    ...data,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  }

  return new Promise<string>((resolve, reject) => {
    firestore
      .collection(getP2AMessagesCollection(roomId))
      .add(message)
      .then((data) => {
        resolve(data.id)
      })
      .catch((err) => reject(err))
  })
}
