const projects = {
  'cloudmeet-e40c3': {
    apiKey: 'AIzaSyBjkhUtmJ8gd_ZM1Ow8C3ja-U6fi3LJOoQ',
    authDomain: 'cloudmeet-e40c3.firebaseapp.com',
    projectId: 'cloudmeet-e40c3',
    storageBucket: 'cloudmeet-e40c3.appspot.com',
    messagingSenderId: '309266500388',
    appId: '1:309266500388:web:d198f38f8271cfe287c1b1',
    measurementId: 'G-5NV5Q1C8Z1',
  },
  'cloudmeet-staging': {
    apiKey: 'AIzaSyCaUsr0UUhym6lwQ_Mc0_jj2Ws68e1UpxI',
    authDomain: 'cloudmeet-staging.firebaseapp.com',
    projectId: 'cloudmeet-staging',
    storageBucket: 'cloudmeet-staging.appspot.com',
    messagingSenderId: '890169516482',
    appId: '1:890169516482:web:e3d26ee41a0e00300cb65b',
    measurementId: 'G-349E4WCC5S',
  },
  'cloudmeet-prod': {
    apiKey: 'AIzaSyDNERUc1Kirt2wkMRK-4ywel0BNrhtkqqE',
    authDomain: 'cloudmeet-prod.firebaseapp.com',
    projectId: 'cloudmeet-prod',
    storageBucket: 'cloudmeet-prod.appspot.com',
    messagingSenderId: '487749554725',
    appId: '1:487749554725:web:372af346f790e78405e5cc',
    measurementId: 'G-VXLWDHYYXB',
  },
}
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID || 'cloudmeet-e40c3'
// @ts-ignore
const firebaseConfig = projects[projectId]

export default firebaseConfig
