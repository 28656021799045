import { css } from '@emotion/css'

export const wrapper = css`
  & .stop-breakout-session-btn {
    max-width: 120px;
    position: absolute !important;
    top: 8px;
  }

  & .breakout-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px 22px;
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }

    & .breakout-action {
      & .breakout-action-title {
        display: flex;
        padding: 5px;
        font-weight: 600;
        color: #ffffff;
      }

      & .breakout-action-remaining-time {
        color: #ffffff;
      }
    }
  }

  & .breakout-rooms {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px;

    & .breakout-room {
      padding: 16px;
      margin: 8px;
      background: #2d3748;
      border-radius: 6px;
      border: 1px solid #d3d9e0;
      width: 346px;
      max-width: 346px;

      & .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & .title-wrap {
          padding: 8px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & .title {
            font-weight: 600;
            color: #ffffff;
          }

          & .total-participants {
            color: #d3d9e0;
          }
        }
      }

      & .content-wrap {
        padding: 0 0 12px 6px;
      }
      & .participants-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & .participant-list-item {
          padding: 4px;
          margin: 4px 0px;
        }
      }
    }
  }
`

export const avatarDefaults = {
  // size: { xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 },
  size: 40,
  style: { backgroundColor: '#0072d6' },
  styleMore: { backgroundColor: '#2FC5D2', color: '#FFFFFF' },
}
