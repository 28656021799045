import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sharedStateAction, sharedPersistedStateAction } from '@cloudmeet/web-core/common/actions'

const useSharedState = <S = undefined>(
  name: string,
  initialState: S | (() => S),
  store?: boolean,
): [S, (newState: S) => void] => {
  const dispatch = useDispatch()

  const storedValue = useSelector((s: any) => (store ? s.sharedPersistedState[name] : s.sharedState[name]))
  const [value, setValue] = useState<S>(storedValue ?? initialState)

  const setState = (newValue: S): void => {
    if (value === newValue) return

    const action = store
      ? sharedPersistedStateAction({
          value: newValue,
          name: name,
        })
      : sharedStateAction({
          value: newValue,
          name: name,
        })

    dispatch(action)
  }

  useEffect(() => {
    if (storedValue != undefined && value !== storedValue) {
      setValue(storedValue)
    }
  }, [storedValue])

  return [value, setState]
}

export default useSharedState

export const SharedStateKeys = {
  LastQuestionId: 'lastQuestionId',
  ChatMessageToNote: 'chatMessageToNote',
  lastP2aIdViewed: 'lastP2aIdViewed',
  lastP2pIdViewed: 'lastP2pIdViewed',
  ChatMessagesOnNotes: 'chatMessagesOnNotes',
  IsFullscreenModeOn: 'isFullscreenModeOn',
  TriggerMuteAll: 'TriggerMuteAll',
}
