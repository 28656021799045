import { useState } from 'react'
import * as Routes from '../config/routes'
import Components from '@cloudmeet/web-components'
import { showErrors } from '@cloudmeet/web-components/src/utils/notifications'

const { error } = Components.Utils.Notifications

export type CommandResponse<T> = {
  errors: string[]
  data: T | null
  hasErrors: boolean
  completed: boolean
}

const ok = <T>(data: any): CommandResponse<T> => {
  return {
    errors: [],
    hasErrors: false,
    completed: true,
    data,
  }
}

const badRequest = <T>(errors: []): CommandResponse<T> => {
  return {
    errors,
    hasErrors: true,
    completed: false,
    data: null,
  }
}

export type SendCommandRequestOptions = {
  showErrors: boolean
}

export default () => {
  const [isLoading, setIsLoading] = useState(false)

  const sendCommandRequest = async <T>(
    func: Function,
    opts?: SendCommandRequestOptions,
  ): Promise<CommandResponse<T>> => {
    try {
      setIsLoading(true)
      const result = await func()
      setIsLoading(false)
      return ok(result)
    } catch (err) {
      setIsLoading(false)

      const displayErrors = !opts || opts.showErrors

      if (err.response && err.response.status === 400) {
        let errors
        try {
          errors = err.response.data.map((x: any) => x.message)
        } catch (e) {
          errors = ['An error occurred. Please try again']
        }

        if (displayErrors) {
          showErrors(errors)
        }
        return badRequest(errors)
      }

      if (displayErrors) {
        await error('An error occurred while processing your request.')
      }
      return badRequest([])
    }
  }

  return {
    isLoading,
    sendCommandRequest,
  }
}
