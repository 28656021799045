import React from 'react'
import Components from '@cloudmeet/web-components'
import * as Style from './style'

const { Button } = Components.UI

const Component: React.FC = () => {
  return (
    <div className={Style.container}>
      <div className="content">
        <img src="/assets/images/browser.svg" className={'browser-icon'} />
        <h1>Browser not supported.</h1>
        <h2>
          Use <b>Google Chrome version 91</b> or higher to access the presentation. You can download the browser by
          using the link below.
        </h2>
        <Button
          type={'normal'}
          label={'Download Chrome'}
          icon={<img src="/assets/images/chrome_icon.svg" />}
          onClick={() => {
            window.location.href = 'https://www.google.com/chrome/'
          }}
        />
      </div>
    </div>
  )
}

export default Component
