import React, { useEffect, useRef, useState } from 'react'
import { Formik, FormikValues, useFormik } from 'formik'
import Components from '@cloudmeet/web-components'
import { useHistory } from 'react-router-dom'

import * as Style from './style'
import { OptionType } from '../common/types/commons'
import { AvailableMediaDevicesType } from './schemas'
import { ATTENDEE, BOOTH, KEYNOTE, PANEL_GROUP } from '../common/config/routes'
import * as Yup from 'yup'

import {
  getDetailsForParticipant,
  GetDetailsForParticipantResponse,
  startPresentation,
} from '@cloudmeet/web-core/rooms/api'
import { RoomParticipantPermission, RoomRecordingOption, RoomType } from '@cloudmeet/web-core/rooms/models'
import { getAvailableMediaDevices, getFirstOrDefaultDevice, getMediaStream } from '@cloudmeet/web-core/media/helpers'
import { isMozillaBrowser } from '@cloudmeet/web-core/common/utils/browserUtils'
import { ROUND_TABLE } from '@cloudmeet/web-ui/src/appContainer/routes'
import useAuthState from '../common/hooks/useAuthState'
import useMediaState, { Media } from '../common/hooks/useMediaState'
import useCommandRequestV2 from '../common/hooks/useCommandRequestV2'
import useQueryRequestV2 from '../common/hooks/useQueryRequestV2'
import listenForRoomChanges from '@cloudmeet/web-core/rooms/listenForRoomChanges'

const { Button, Select, Switch, Input } = Components.UI
const { useDidMountEffect } = Components.Hooks

const VIDEO_DIMENSIONS = {
  width: 382,
  height: 300,
}

export default () => {
  const history = useHistory()
  const videoRef = useRef()

  /** ------------------------------------ **
   Entity states
   ** ------------------------------------ **/
  const [media, setMedia] = useMediaState()
  const [auth, setAuth] = useAuthState()

  const [availableDevices, setAvailableDevices]: [AvailableMediaDevicesType, Function] = useState({
    videoDevices: [],
    audioInputDevices: [],
    audioOutputDevices: [],
  })
  const [showAudioVideoSettings, setShowAudioVideoSettings] = useState(false)
  const [nameRecordingOptionFormInitialValues, setNameRecordingOptionFormInitialValues] = useState<{
    participantName: string
    recordSession: boolean
  }>({
    participantName: auth.participantName,
    recordSession: false,
  })
  const [sendStartPresentation, isStartPresentationRequestLoading] = useCommandRequestV2()
  const [
    ,
    isGetRoomDetailsForParticipantLoading,
    sendGetRoomDetailsForParticipant,
  ] = useQueryRequestV2<GetDetailsForParticipantResponse | null>(null)

  const refreshAuth = async () => {
    const result = await sendGetRoomDetailsForParticipant(() =>
      getDetailsForParticipant({
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        participantKey: auth.participantKey,
        participantName: auth.participantName,
      }),
    )

    if (result) {
      setAuth({
        ...result,
      })
    }
  }

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/
  const joinMeeting = async (values: any) => {
    let redirectToVideoConference = true

    if (auth.canStartPresentation && !auth.presentationStarted) {
      const result = await sendStartPresentation(() =>
        startPresentation({
          roomId: auth.roomId,
          roomKey: auth.roomKey,
          participantKey: auth.participantKey,
          recordSession: values.recordSession ?? false,
        }),
      )

      if (!result.completed) {
        redirectToVideoConference = false
      }
    }

    if (redirectToVideoConference) {
      await refreshAuth()
      //We need to turn off the camera before entering video conference
      //to fix the issue with camera light staying on event the video is off AB#37230
      if (videoRef && videoRef.current) {
        ;(videoRef.current as any).srcObject.getVideoTracks().forEach((t: MediaStreamTrack) => t.stop())
      }
      history.push(getRedirectUrl())
    }
  }

  const toOptions = (media: MediaDeviceInfo): OptionType => ({
    label: media.label,
    value: media.label,
  })

  const form = useFormik<Media>({
    initialValues: media,
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: Yup.object().shape({}),
  })

  const updateVideoSource = (stream: MediaStream | null) => {
    if (videoRef && videoRef.current) {
      ;(videoRef.current as any).srcObject = stream
    }
  }

  const getRedirectUrl = (): string => {
    if (auth.isOnlyViewer) {
      return ATTENDEE
    }

    if (auth.roomType === RoomType.Booth) return BOOTH
    else if (auth.roomType === RoomType.Keynote) return KEYNOTE
    else if (auth.roomType === RoomType.RoundTable) return ROUND_TABLE
    else return PANEL_GROUP
  }

  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/

  useEffect(() => {
    ;(async () => {
      setNameRecordingOptionFormInitialValues({
        participantName: auth.participantName,
        recordSession: auth.requiresRecording,
      })

      setShowAudioVideoSettings(!auth.isOnlyViewer)

      if (!auth.isOnlyViewer) {
        await fillAudioVideoDevices()
      }
    })()
  }, [])

  useEffect(() => {
    if (!auth.canStartPresentation) return

    const unsubscribeListenForRoomChanges = listenForRoomChanges(auth.roomId, async (data: any) => {
      if (
        auth.presentationStarted !== data.presentationStarted &&
        auth.participantKey !== data.presentationStartedTriggeredBy
      ) {
        setAuth({
          ...auth,
          presentationStarted: data.presentationStarted,
        })
      }
    })

    return () => {
      unsubscribeListenForRoomChanges()
    }
  }, [])

  /**
   * Require media permissions
   * Get all devices and create mediaStream
   * Put devices to initial form
   * Update video source with selected video device
   */
  const fillAudioVideoDevices = async () => {
    //Required to get user devices
    await getMediaStream({ video: true, audio: true })

    let availableDevices = await getAvailableMediaDevices()

    /***
     * Mozilla firefox browser specific (Security): for media devices to read MediaDeviceInfo.Label, the MediaDevice
     * stream need to be streamed in a video elements
     * reference: https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo/label
     */
    if (isMozillaBrowser) {
      availableDevices = await getAvailableMediaDevices()
    }

    setAvailableDevices(availableDevices)

    const mediaDefaults = {
      videoDevice: media.videoDevice || getFirstOrDefaultDevice(availableDevices.videoDevices),
      audioInputDevice: media.audioInputDevice || getFirstOrDefaultDevice(availableDevices.audioInputDevices),
      audioOutputDevice: media.audioOutputDevice || getFirstOrDefaultDevice(availableDevices.audioOutputDevices),
      videoEnabled: true,
      audioEnabled: true,
    }

    const videoDevice = availableDevices.videoDevices.find((x) => x.label === mediaDefaults.videoDevice)
    const audioDevice = availableDevices.audioInputDevices.find((x) => x.label === mediaDefaults.audioInputDevice)
    const stream = await getMediaStream({
      video: { deviceId: videoDevice?.deviceId, ...VIDEO_DIMENSIONS },
      audio: { deviceId: audioDevice?.deviceId },
    })

    if (stream) {
      updateVideoSource(stream)
    }

    setMedia(mediaDefaults)
  }

  useDidMountEffect(() => {
    ;(async () => {
      if (
        media &&
        (media.videoDevice !== form.values.videoDevice ||
          (media.videoEnabled != form.values.videoEnabled && form.values.videoEnabled))
      ) {
        const videoDevice = availableDevices.videoDevices.find((x) => x.label === form.values.videoDevice)
        const audioDevice = availableDevices.audioInputDevices.find((x) => x.label === form.values.audioInputDevice)
        const stream = await getMediaStream({
          video: { deviceId: videoDevice?.deviceId, ...VIDEO_DIMENSIONS },
          audio: { deviceId: audioDevice?.deviceId },
        })

        if (stream) {
          updateVideoSource(stream)
        }
      }

      if (media.videoEnabled != form.values.videoEnabled && !form.values.videoEnabled) {
        if (videoRef && videoRef.current) {
          ;(videoRef.current as any).srcObject.getVideoTracks().forEach((t: MediaStreamTrack) => t.stop())
        }
      }

      setMedia({
        ...form.values,
      })
    })()
  }, [form.values])

  return (
    <div className={Style.body}>
      <div
        className={Style.mainContainer}
        style={showAudioVideoSettings ? { justifyContent: 'space-between' } : { justifyContent: 'center' }}
      >
        {showAudioVideoSettings && (
          <div className={Style.videoWrap}>
            <video
              className={Style.videoContainer}
              ref={videoRef as any}
              autoPlay={true}
              controls={false}
              muted={true}
              style={media.videoEnabled ? { visibility: 'visible' } : { visibility: 'hidden' }}
            />

            <div className={Style.toggleDevicesWrap}>
              <div>
                <Switch label="Camera" labelInline={true} name="videoEnabled" {...form} />
              </div>
              <div>
                <Switch label="Audio" labelInline={true} name="audioEnabled" {...form} />
              </div>
            </div>
          </div>
        )}

        <div
          className={Style.formContainer}
          style={showAudioVideoSettings ? { width: 'auto' } : { width: '400px', marginLeft: '0px' }}
        >
          {showAudioVideoSettings && (
            <>
              <div className={Style.switchRow}>
                <div className={Style.switchTitle}>
                  <i className="ri-vidicon-line mr-4" />
                  <span>Camera</span>
                </div>
                <Select
                  name="videoDevice"
                  options={availableDevices.videoDevices.map((media, index) => toOptions(media))}
                  {...form}
                  disabled={!media.videoEnabled}
                />
              </div>

              <hr />

              <div className={Style.switchRow}>
                <div className={Style.switchTitle}>
                  <i className="ri-mic-line mr-4" />
                  <span>Microphone</span>
                </div>
                <div className={Style.audioSelectorContainer}>
                  <Select
                    name="audioInputDevice"
                    options={availableDevices.audioInputDevices.map((media, index) => toOptions(media))}
                    {...form}
                    disabled={!media.audioEnabled}
                  />
                </div>
              </div>
              {!isMozillaBrowser && (
                <>
                  <div className={Style.switchRow}>
                    <div className={Style.switchTitle}>
                      <i className="ri-volume-up-line mr-4" />
                      <span>Speaker</span>
                    </div>
                    <div className={Style.audioSelectorContainer}>
                      <Select
                        name="audioOutputDevice"
                        options={availableDevices.audioOutputDevices.map((media, index) => toOptions(media))}
                        {...form}
                        disabled={!media.audioEnabled}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          <Formik
            enableReinitialize
            initialValues={nameRecordingOptionFormInitialValues}
            validationSchema={Yup.object().shape({})}
            onSubmit={(values: FormikValues) => joinMeeting(values)}
          >
            {(form) => (
              <div>
                {auth.canStartPresentation && !auth.presentationStarted && (
                  <>
                    <div className={Style.recordingOptionWrap}>
                      <hr />
                      {auth.recordingOption === RoomRecordingOption.NoRecording && (
                        <div className={Style.recordingOptionItem}>
                          <i className="ri-information-fill mr-4" />
                          <span>Meeting will not be recorded</span>
                        </div>
                      )}

                      {auth.recordingOption === RoomRecordingOption.RecordingIsRequired && (
                        <div className={Style.recordingOptionItem}>
                          <i className="ri-information-fill mr-4" />
                          <span>Recording will start automatically</span>
                        </div>
                      )}

                      {auth.recordingOption === RoomRecordingOption.RecordingIsDefinedByHost && (
                        <div className={Style.switchRecordingContainer}>
                          <div className={'mr-16 mt-8'}>Record meeting?</div>
                          <Switch label="" labelInline={true} name="recordSession" {...form} />
                        </div>
                      )}
                    </div>
                    <hr />
                  </>
                )}

                <Input
                  onChangeFunction={(newValue: any) => {
                    setAuth({
                      ...auth,
                      participantName: newValue,
                    })
                  }}
                  {...form}
                  name={'participantName'}
                  label={'Enter your name'}
                />

                <div className={Style.actionBtn}>
                  <Button
                    loading={isStartPresentationRequestLoading || isGetRoomDetailsForParticipantLoading}
                    disabled={
                      isStartPresentationRequestLoading ||
                      isGetRoomDetailsForParticipantLoading ||
                      !auth.participantName
                    }
                    label={auth.canStartPresentation && !auth.presentationStarted ? 'Start meeting' : 'Join meeting'}
                    type="normal"
                    onClick={(e) => form.handleSubmit()}
                  />
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
