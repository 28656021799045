import useSharedState from './useSharedState'

const useSharePersistedState = <S = undefined>(
  name: string,
  initialState: S | (() => S),
): [S, (newState: S) => void] => {
  return useSharedState<S>(name, initialState, true)
}

export default useSharePersistedState
