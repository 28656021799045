import { css } from '@emotion/css'
import Components from '@cloudmeet/web-components'

const { Colors } = Components.Styles

export const container = css`
  display: flex;
  flex-direction: column;
  margin: -1rem;
`

export const queueItemContainer = css`
  display: flex;
  flex-direction: column;
`

export const queueEmpty = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  & i {
    font-size: 48px;
    color: ${Colors.PrimaryColor};
  }
  h3 {
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    color: ${Colors.Gray8};
    margin-top: 10px;
  }
`

export const queueItemContent = css`
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #e2e8f0;
  padding: 1rem;
`

export const queueItemTitle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const queueItemTitleContainer = css`
  display: flex;
  flex-direction: row;
`

export const queueItemText = css`
  margin-left: 50px;
  margin-top: 10px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.Gray8};
`

export const queueItemDate = css`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${Colors.Gray6};
  margin-top: 0px;
`

export const queueItemActions = css`
  margin-top: 7px;
`

export const queueItemName = css`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.Gray10};
`

export const queueItemIcon = css`
  background-color: ${Colors.PrimaryColor};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  & i {
    font-size: 20px;
  }
`

export const queueSection = css`
  display: flex;
  flex-direction: column;
`

export const queueSectionTitle = css`
  display: flex;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  background-color: ${Colors.Gray3};
  color: ${Colors.Gray7};
  padding: 4px 16px;
  margin: 10px 0;
  justify-content: space-between;
`

export const queueSectionCancelAll = css`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #f5222d;
  cursor: pointer;
  transition: font-weight ease-in 0.2s;
  &:hover {
    font-weight: 600;
    transition: font-weight ease-in 0.2s;
  }
`

export const queueSectionBody = css`
  display: flex;
  flex-direction: column;
  //margin: 1rem !important;
`

export const actionBtn = css`
  background-color: #52c41a !important;
  color: white !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  border: none !important;
  height: 25px !important;
`

export const nextBtnMain = css`
  ${actionBtn};
  margin: 1rem !important;
  height: auto !important;
  background-color: #52c41a !important;
  & i {
    margin-left: 5px;
  }
`

export const nextBtn = css`
  ${actionBtn};
  background-color: #52c41a !important;
  width: 60px;
  & i {
    margin-left: 5px;
  }
`

export const verifyBtn = css`
  ${actionBtn};
  width: 55px !important;
  background-color: #0072d6 !important;
`

export const closeBtn = css`
  ${actionBtn};
  width: 55px !important;
  background-color: #f5222d !important;
`

export const deleteBtn = css`
  ${actionBtn};
  border-radius: 100% !important;
  width: 25px !important;
  height: 25px !important;
  background-color: #fff1f0 !important;
  margin-left: 8px !important;
  padding: 5px !important;
  i {
    color: #ff7875;
    font-size: 18px;
  }
`

export const actions = css`
  display: flex;
  width: 100px;
  justify-content: space-between;
  align-items: center;
`

export const morePopover = css``

export const morePopoverContent = css`
  display: flex;
  flex-direction: column;
`

export const morePopoverItem = css`
  display: flex;
  flex-direction: row;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all linear 0.2s;
  p {
    margin-left: 10px;
  }
  .ri-checkbox-circle-line {
    font-size: 18px;
    color: #52c41a;
  }
  .ri-close-circle-line {
    font-size: 18px;
    color: #f5222d;
  }
  &:hover {
    font-weight: bold;
    transition: all linear 0.2s;
  }
`

export const popoverButton = css`
  border: none !important;
  background: none !important;
  transition: all linear 0.2s !important;
  border-radius: 5px !important;
  &:hover,
  &:active,
  &:focus {
    background: #f7f8fa !important;
    transition: all linear 0.2s !important;
  }
`
