import React, { CSSProperties, useEffect } from 'react'

import * as Style from './style'

export type Props = {
  title: string
  description?: string
  style?: CSSProperties
}

export default ({ title, description, style }: Props) => {
  /** ------------------------------------ **
   Hooks declaration
   ** ------------------------------------ **/

  /** ------------------------------------ **
   Actions declaration
   ** ------------------------------------ **/

  /** ------------------------------------ **
   Entity states
   ** ------------------------------------ **/

  /** ------------------------------------ **
   Local States
   ** ------------------------------------ **/

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/

  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/

  /** ------------------------------------ **
   Helper Components
   ** ------------------------------------ **/

  const body = (
    <div className={Style.bgContainer} style={style}>
      <div>
        <div className={Style.title}>{title}</div>
        {description && <div className={Style.description}>{description}</div>}
      </div>
    </div>
  )
  return <div>{body}</div>
}
