import callApi from '../common/api/apiRequest'

type KickOutParticipantRequest = {
  roomId: string
  roomKey: string
  participantKey: string
  participantKeyToKickOut: string
}

export const kickOutParticipant = async (request: KickOutParticipantRequest) => {
  return callApi<void>('put', `rooms/kickOutParticipant`, request)
}
