import { ParticipantDto } from '../models'
import { Role } from '../../tenants/models'
import { uuid } from '../../common/utils/idUtils'
import BreakoutRoom from './breakoutRoom'

export default (
  requestedTotalRooms: number,
  participants: ParticipantDto[],
  participantKey: string,
): BreakoutRoom[] => {
  const presenters = participants.filter((x) => x.role === Role.Presenter && x.participantKey !== participantKey)
  const attendees = participants.filter((x) => x.role === Role.Attendee)
  const result: BreakoutRoom[] = []
  const allParticipants = presenters.concat(attendees)

  const totalPossibleRooms = presenters.length + attendees.length
  if (totalPossibleRooms === 0) return []

  for (let i = 0; i < requestedTotalRooms; i++) {
    const breakoutRoom: BreakoutRoom = {
      id: uuid(),
      roomKey: uuid(),
      name: 'Room ' + (result.length + 1),
      participants: [],
    }
    result.push(breakoutRoom)
  }

  let roomCounter = 0
  for (let i = 0; i < allParticipants.length; i++) {
    result[roomCounter].participants.push(allParticipants[i])
    roomCounter = roomCounter < result.length - 1 ? roomCounter + 1 : 0
  }

  return result
}
