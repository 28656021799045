import { firestore, getP2PMessagesCollection } from '../common/lib/firebase'

export default (roomId: string, observer: (data: any) => void) => {
  return firestore
    .collection(getP2PMessagesCollection(roomId))
    .orderBy('createdAt')
    .onSnapshot((result) => {
      const data: any[] = []
      result.docs.forEach((x) => {
        data.push(x.data())
      })
      observer(data)
    })
}
