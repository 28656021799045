import React from 'react'

import * as Style from './style'

export default () => {
  const body = (
    <div className={Style.bgContainer}>
      <div>
        <div className={Style.title}>You are not allowed to participate in this meeting.</div>
      </div>
    </div>
  )
  return <div>{body}</div>
}
