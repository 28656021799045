import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import firebaseConfig from '../config/firbase'

export const ROOMS_COLLECTION_NAME = 'rooms'
export const P2A_MESSAGES_COLL_NAME = 'p2a_messages'
export const P2P_MESSAGES_COLL_NAME = 'p2p_messages'
export const QUESTIONS_COLL_NAME = 'questions'
export const PARTICIPANTS_COLL_NAME = 'participants'

/**
 * Initialise firebase
 */
firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebase.auth()
const firestore = firebase.firestore()

/**
 * Define collection compositions
 */
export const getRoomCollection = (roomId: string) => `${ROOMS_COLLECTION_NAME}/${roomId}`
export const getQuestionsCollection = (roomId: string) => `${ROOMS_COLLECTION_NAME}/${roomId}/${QUESTIONS_COLL_NAME}`
export const getParticipantsCollection = (roomId: string) =>
  `${ROOMS_COLLECTION_NAME}/${roomId}/${PARTICIPANTS_COLL_NAME}`
export const getP2AMessagesCollection = (roomId: string) => `${getRoomCollection(roomId)}/${P2A_MESSAGES_COLL_NAME}`
export const getP2PMessagesCollection = (roomId: string) => `${getRoomCollection(roomId)}/${P2P_MESSAGES_COLL_NAME}`

export { firebase, firebaseAuth, firestore }
