import ApiConfig from '@cloudmeet/web-core/common/config/api'

const sendRemoveParticipantBeacon = (request: { roomId: string; roomKey: string; participantKey: string }): boolean => {
  const body = {
    roomId: request.roomId,
    roomKey: request.roomKey,
    participantKey: request.participantKey,
  }
  const headers = {
    type: 'application/json',
  }
  const data = new Blob([JSON.stringify(body)], headers)

  return navigator.sendBeacon(`${ApiConfig.baseURL}/rooms/removeParticipant`, data)
}

export default sendRemoveParticipantBeacon
