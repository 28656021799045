import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Components from '@cloudmeet/web-components'

import * as Style from './style'
import { Formik, FormikValues, useFormik } from 'formik'

import { getNotes, NoteDto, saveNote } from '@cloudmeet/web-core/notes/api'
import useQueryRequest from '../common/hooks/useQueryRequest'
import useCommandRequest from '../common/hooks/useCommandRequest'
import useSharedState, { SharedStateKeys } from '../common/hooks/useSharedState'
import { diffWithNow } from '@cloudmeet/web-core/common/utils/dateUtils'
import { success } from '@cloudmeet/web-components/src/utils/notifications'
import useSharePersistedState from '../common/hooks/useSharedPersistedState'

/** ------------------------------------ **
 Global declarations
 ** ------------------------------------ **/
const { Button, Input, Textarea, Timer } = Components.UI

export type NotesProps = {
  noteTimerStartsAt?: string
  showAddNotesForm?: boolean
  onNoteClicked?: (note: any) => void
  roomId: string
  roomKey: string
  participantKey: string
}

export default ({
  noteTimerStartsAt,
  showAddNotesForm,
  onNoteClicked,
  roomId,
  roomKey,
  participantKey,
}: NotesProps) => {
  /** ------------------------------------ **
   Local States
   ** ------------------------------------ **/
  const [chatMessageToNote] = useSharedState<{ id: string; message: string } | null>(
    SharedStateKeys.ChatMessageToNote,
    null,
  )
  const [chatMessagesOnNotes, setChatMessagesOnNotes] = useSharePersistedState<string[]>(
    SharedStateKeys.ChatMessagesOnNotes,
    [],
  )
  const [notes, setNotes] = useState<NoteDto[] | null>(null)
  const { sendQueryRequest } = useQueryRequest()
  const { isLoading: isSaving, sendCommandRequest: sendSaveNoteRequest } = useCommandRequest()
  const [initialInsertFormValues] = useState({})
  const [initialSearchFormValues] = useState({
    q: '',
  })
  const [videoSeek, setVideoSeek] = useState(0)

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/
  const onSubmitInsertNote = async (values: any) => {
    if (!noteTimerStartsAt) return

    await saveNoteHandler(values.content, videoSeek)
  }

  const saveNoteHandler = async (content: string, videoSeekInSeconds: number): Promise<boolean> => {
    const result = await sendSaveNoteRequest(() =>
      saveNote({
        roomId: roomId,
        roomKey: roomKey,
        participantKey: participantKey,
        content: content,
        videoSeek: videoSeekInSeconds,
      }),
    )

    if (!result.hasErrors) {
      await refreshNotes()
      setVideoSeek(0)
      insertNotForm.resetForm()
      return true
    }
    return false
  }

  const onSearchNote = async (values: any) => {
    await refreshNotes(values.q)
  }

  const refreshNotes = async (q?: string) => {
    const result = await sendQueryRequest<NoteDto[]>(() =>
      getNotes({
        roomId,
        roomKey,
        participantKey,
        q,
      }),
    )

    setNotes(result)
  }

  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/
  useEffect(() => {
    ;(async () => {
      await refreshNotes()
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (chatMessageToNote && noteTimerStartsAt) {
        const result = await saveNoteHandler(
          chatMessageToNote.message,
          Math.round(diffWithNow(noteTimerStartsAt).asSeconds()),
        )
        if (result) {
          setChatMessagesOnNotes([...chatMessagesOnNotes, chatMessageToNote.id])
          success('Message added to notes')
        }
      }
    })()
  }, [chatMessageToNote])

  const insertNotForm = useFormik({
    initialValues: initialInsertFormValues,
    enableReinitialize: true,
    onSubmit: onSubmitInsertNote,
    validationSchema: Yup.object().shape({}),
  })

  /** ------------------------------------ **
   Main Component
   ** ------------------------------------ **/
  return (
    <div>
      <div className={Style.notesContainer}>
        {showAddNotesForm && (
          <>
            <Textarea
              name={'content'}
              onChangeFunction={() => {
                if (!noteTimerStartsAt) return

                setVideoSeek(Math.round(diffWithNow(noteTimerStartsAt).asSeconds()))
              }}
              {...insertNotForm}
              placeholder={'Enter your note here'}
            />
            <div className={Style.flexRowSpaceBetween}>
              <div>
                {noteTimerStartsAt && (
                  <div className={Style.notesTimerContainer}>
                    <div>
                      <i className="ri-time-line" />
                    </div>
                    <div className={Style.notesTimer}>
                      <Timer date={noteTimerStartsAt} type={'Timer'} />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <Button
                  loading={isSaving}
                  disabled={isSaving || !noteTimerStartsAt || !(insertNotForm.values as any)?.content}
                  type={'normal'}
                  label={'Save note'}
                  onClick={() => {
                    insertNotForm.handleSubmit()
                  }}
                />
              </div>
            </div>
          </>
        )}
        <hr />
        <div className={Style.notesListContainer}>
          <div className={Style.notesListHead}>
            <div>
              <h4>Notes</h4>
            </div>
            <div>
              <Formik
                enableReinitialize
                initialValues={initialSearchFormValues}
                validationSchema={Yup.object().shape({})}
                onSubmit={(values: FormikValues) => onSearchNote(values)}
              >
                {(form) => (
                  <>
                    <Input
                      name={'q'}
                      {...form}
                      placeholder={'Search'}
                      handleSubmit={() => {
                        form.handleSubmit()
                      }}
                    />
                  </>
                )}
              </Formik>
            </div>
          </div>
          <div className={Style.noteListBody}>
            {notes &&
              notes.map((note: NoteDto, index: any) => (
                <div key={note.id} className={Style.noteListItem} onClick={() => onNoteClicked?.(note)}>
                  <div className={Style.noteListItemTimeInfo}>
                    #{index + 1} at {note.noteTime}
                  </div>
                  <div className={Style.noteListItemContent}>{note.content}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
