import callApi from '../../common/api/apiRequest'
import { Role } from '../../tenants/models'

type BreakoutRoomsSessionRequest = {
  roomId: string
  roomKey: string
  participantKey: string
}

export type BreakoutRoomsWithParticipantsResponse = {
  roomId: string
  roomKey: string
  participantKey: string
  name: string
  participants: BreakoutRoomParticipant[]
}

export type BreakoutRoomParticipant = {
  id: string
  name: string
  role: Role
  participantKey: string
  isHost: boolean
}

export const getBreakoutRoomsSession = async (request: BreakoutRoomsSessionRequest) => {
  return callApi<BreakoutRoomsWithParticipantsResponse[]>('get', `rooms/breakoutRooms`, request)
}
