import { firebase, firebaseAuth } from '../common/lib/firebase'

export default (realTimeStoreJwt: string): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    firebaseAuth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebaseAuth.signInWithCustomToken(realTimeStoreJwt))
      .then(() => {
        if (!firebaseAuth.currentUser || !firebaseAuth.currentUser.uid) {
          resolve(false)
          return
        }
        resolve(true)
      })
      .catch((error) => {
        console.error(error)
        resolve(false)
      })
  })
}
