import { Question } from './models'
import { firestore, getQuestionsCollection } from '../common/lib/firebase'

export const getQuestion = (roomId: string, questionId: string): Promise<any | null> => {
  return new Promise((resolve, reject) => {
    firestore
      .collection(getQuestionsCollection(roomId))
      .doc(questionId)
      .get()
      .then((x: any): any => {
        const data = x.data()
        resolve(data)
      })
      .catch((err: any) => reject(err))
  })
}
