import { Question } from './models'
import { firestore, getQuestionsCollection } from '../common/lib/firebase'

export const getLastQuestionForVerification = (roomId: string): Promise<Question | null> => {
  return new Promise((resolve, reject) => {
    firestore
      .collection(getQuestionsCollection(roomId))
      .where('status', '==', 'REQUESTED_VERIFICATION')
      .get()
      .then((x): any => {
        let result = null
        x.forEach((x) => {
          result = x.data()
        })
        resolve(result)
      })
      .catch((err) => reject(err))
  })
}
