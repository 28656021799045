import callApi from '../common/api/apiRequest'

export type RealtimeStoreCredentialsResponse = {
  jwt: string
}

export type GetRealtimeStoreCredentialsRequest = {
  roomId: string
  roomKey: string
  participantKey: string
}

export default async (request: GetRealtimeStoreCredentialsRequest): Promise<RealtimeStoreCredentialsResponse> => {
  return callApi<RealtimeStoreCredentialsResponse>('get', `rooms/realtimeStoreCredentials`, request)
}
