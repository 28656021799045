import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as Routes from '../common/config/routes'
import { AUDIO_VIDEO_SETTINGS } from '../common/config/routes'

import { getDetailsForParticipant, GetDetailsForParticipantResponse } from '@cloudmeet/web-core/rooms/api'
import RoomLoader from '../common/components/RoomLoader'
import Components from '@cloudmeet/web-components'
import signInWithRealtimeStore from '@cloudmeet/web-core/rooms/signInWithRealtimeStore'
import useQueryRequestV2 from '../common/hooks/useQueryRequestV2'

import useAuthState from '../common/hooks/useAuthState'
import getRealtimeStoreCredentials, {
  RealtimeStoreCredentialsResponse,
} from '@cloudmeet/web-core/rooms/getRealtimeStoreCredentials'
const { error } = Components.Utils.Notifications
const { storeTheme } = Components.Utils.Theme
const { isAllowedBrowser } = Components.Utils.Browser

export default () => {
  const history = useHistory()
  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const [auth, setAuth] = useAuthState()
  const [, , sendGetRoomDetailsForParticipant] = useQueryRequestV2<GetDetailsForParticipantResponse | null>(null)
  const [, , sendGetRealtimeStoreCredentials] = useQueryRequestV2<RealtimeStoreCredentialsResponse | null>()

  useEffect(() => {
    ;(async () => {
      const roomDetailsForParticipant = await sendGetRoomDetailsForParticipant(() =>
        getDetailsForParticipant({
          roomId: params.get('roomId') ?? '',
          roomKey: params.get('roomKey') ?? '',
          participantKey: params.get('participantKey') ?? auth.participantKey,
          participantName: auth.participantName,
        }),
      )

      if (!roomDetailsForParticipant) {
        history.replace(Routes.NOT_FOUND)
        return
      }

      if (roomDetailsForParticipant.kickedOut) {
        history.replace(Routes.NOT_AUTHORIZED_URL)
        return
      }

      const realtimeStoreCredentials = await sendGetRealtimeStoreCredentials(() =>
        getRealtimeStoreCredentials({
          roomId: roomDetailsForParticipant.roomId,
          roomKey: roomDetailsForParticipant.roomKey,
          participantKey: roomDetailsForParticipant.participantKey,
        }),
      )

      const isLoggedIn = await signInWithRealtimeStore(realtimeStoreCredentials?.jwt ?? '')
      if (!isLoggedIn) {
        await error('Could not authenticate with realtime storage.')
        return
      }

      setAuth({
        ...roomDetailsForParticipant,
      })

      storeTheme(roomDetailsForParticipant.theme)

      const redirectTo = params.get('redirectTo')
      window.location.href = redirectTo ? redirectTo : AUDIO_VIDEO_SETTINGS
    })()
  }, [])

  return (
    <div>
      <RoomLoader title={'Loading...'} />
    </div>
  )
}
