import callApi from '../common/api/apiRequest'

export type NoteDto = {
  id: string
  content: string
  createdAt: string
  videoSeek: number
  noteTime: string
}

type GetNotesRequest = {
  roomId: string
  roomKey: string
  participantKey: string
  q?: string
}

export const getNotes = async (request: GetNotesRequest) => {
  return callApi<NoteDto[]>(
    'get',
    `notes/${request.roomId}?roomKey=${request.roomKey}&participantKey=${request.participantKey}`,
    {
      q: request.q,
    },
  )
}

type SaveNoteRequest = {
  id?: string
  content: string
  roomKey: string
  roomId: string
  participantKey: string
  videoSeek: number
}
export const saveNote = async (request: SaveNoteRequest) => {
  return callApi<string>('post', `notes`, request)
}
