import { css } from '@emotion/css'

export const container = css`
  overflow: auto;
  width: 100%;
  display: flex;
  height: 100vh;
`

export const viewerContainer = css`
  position: relative;
  width: 75%;
  height: 100vh;
`

export const videoContainer = css`
  width: 100%;
  height: 100vh;
`
export const notesBreakpointContainer = css`
  position: absolute;
  bottom: 27px;
  left: 0;
  z-index: 9999;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
`

export const notesBreakpointsItems = css`
  position: relative;
  width: 100%;
`

export const notesBreakpointsItem = css`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0072d6;
  left: 0;
  cursor: pointer;
`

export const notesContainer = css`
  width: 25%;
  padding: 1rem;
  background: white;

  & hr {
    display: none !important;
  }
  height: 100vh;
  overflow-y: auto;
`
