import React from 'react'
import * as Style from './style'

export default () => {
  return (
    <div>
      <div className={Style.bgContainer}>
        <div>
          <div className={Style.title}>Thank you for participating</div>
        </div>
      </div>
    </div>
  )
}
