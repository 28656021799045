import { css } from '@emotion/css'
import Components from '@cloudmeet/web-components'

const { Colors } = Components.Styles

export const container = css`
  display: flex;
`

export const chatRoomContainer = css`
  height: calc(100vh - 96px);
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  right: 0;
  z-index: 999;
`

export const chatRoomContainerInvisible = css`
  ${container};
  display: none !important;
`

export const header = css`
  padding: 10px;
  background: ${Colors.Gray2};
  height: 56px;
  color: ${Colors.Gray9};
  font-size: 24px;
  font-weight: 600;
  font-family: Inter, sans-serif;
`

export const headerContainer = css``

export const headerTabs = css``

export const body = css`
  padding: 10px;
`

export const scrollContainer = css`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 234px);
`

export const tabs = css`
  padding: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex: 1111;
  & div[role='tabpanel'] {
    display: flex;
    overflow: auto;
    margin-left: 0;
    flex-direction: column;
  }

  .ant-tabs-content-holder {
    height: 100% !important;
    padding: 0 !important;
    overflow: hidden !important;
    width: 100% !important;
  }
`

export const radioGroup = css`
  position: relative;

  & .ant-radio-button-wrapper:first-child {
    border-radius: 6px 0 0 6px !important;
  }
  & .ant-radio-button-wrapper:last-child {
    border-radius: 0 6px 6px 0 !important;
  }

  & .badge {
    position: absolute;
    background: red;
    width: 10px;
    height: 10px;
    top: -5px;
    right: 5px;
    border-radius: 50%;
  }
`

/**
 * Chat Item
 */
export const chatItemContainer = css`
  top: 64px;
  background: #f7f8fa;
  border-radius: 6px;
  display: flex;
  padding: 15px;
  margin: 8px 12px;
  justify-content: space-between;
  align-items: center;
`

export const blueContainer = css`
  background: #e6f7ff;
`

export const cyanContainer = css`
  background: #e6fffb;
`

export const blueText = css`
  color: #0072d6;
`

export const cyanText = css`
  color: #13c2c2;
`

export const chatItemContent = css`
  font-family: Inter, sans-serif;
  margin-left: 12px;
  flex: 1;
  overflow: hidden;
`

export const chatItemNameDate = css`
  display: flex;
`

export const chatItemName = css`
  color: ${Colors.Gray9};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`

export const chatItemDate = css`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.Gray6};
  margin-left: 4px;
`

export const chatItemMessage = css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin: 2px 0;
  overflow: hidden;
`

export const chatItemRole = css`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`

export const chatItemAddToNote = css``
export const chatItemAddToNoteBtn = css`
  padding-left: 0;
  & i {
    color: ${Colors.Gray6} !important;
  }
  &:active {
    i {
      color: ${Colors.PrimaryColor} !important;
    }
  }
`

/**
 Chat footer (Input)
 */
export const footerContainer = css`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`

export const footerInput = css`
  display: flex;
  justify-content: space-around;
  flex: 1;
  align-items: flex-start;
  border-top: 1px solid #f0f2f5;
  background: white;

  & button {
    margin: auto;
  }

  & .inputLabel {
    margin-bottom: 0 !important;
  }

  & input {
    border: none !important;
    width: 280px !important;
    font-family: Inter, sans-serif;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 22px !important;
    padding: 0 15px !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    margin-left: 15px;
    height: 50px !important;

    &:active,
    &:focus {
      outline: none !important;
    }
  }
`

export const chatNotification = css`
  top: calc(25vh - 10px);
  right: 25px;
  z-index: 9999;
  height: 22px;
`
