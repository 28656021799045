import { css } from '@emotion/css'

export const notesContainer = css`
  background: white;

  & hr {
    margin-top: 1rem;
    background: #d3d9e0;
    width: 100%;
    height: 1px;
    border: none;
    margin-bottom: 1rem;
  }
`
export const notesListContainer = css``
export const notesListHead = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1a202c;
  }
`
export const noteListBody = css`
  color: #2d3748;
  font-size: 14px;
  line-height: 22px;
`

export const noteListItem = css`
  margin-bottom: 1rem;
  padding: 1rem;
  cursor: pointer;

  &:nth-child(even) {
    background: #f7f8fa;
    box-shadow: inset 0px -1px 0px #e2e8f0;
  }
`

export const noteListItemTimeInfo = css`
  margin-bottom: 0.5rem;
  font-size: 12px;
  color: #808996;
`
export const noteListItemContent = css`
  font-size: 14px;
  color: #2d3748;
`

export const flexRowSpaceBetween = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const notesTimerContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  border: 1px solid #d3d9e0;

  & i {
    color: #d3d9e0;
  }
`

export const notesTimer = css`
  padding: 0 0.25rem;
  color: #b1b7c0;
`
