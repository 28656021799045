import { css } from '@emotion/css'

export const title = css`
  padding: 4px 8px;
  text-transform: uppercase;
  background: #f7f8fa;
  color: #808996;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 8px;
`
export const container = css`
  & .mute-btn {
    max-width: 100% !important;
    height: auto !important;
  }
`

export const participantContainer = css`
  display: flex;
  padding: 8px;
  align-items: center;
  margin-bottom: 8px;
`

export const participantDetails = css`
  padding-left: 8px;
`

export const participantDetailsName = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #1a202c;
`
export const participantContainerActions = css`
  display: flex;
  margin-left: auto;
`

export const participantDetailsRole = css`
  font-size: 12px;
  line-height: 20px;
`

export const blueContainer = css`
  background: #e6f7ff;
`

export const cyanContainer = css`
  background: #e6fffb;
`

export const blueText = css`
  color: #0072d6;
`

export const cyanText = css`
  color: #13c2c2;
`

export const changeHostContainer = css`
  margin-left: 16px;
  margin-right: 16px;
`
