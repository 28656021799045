import { ROOMS_COLLECTION_NAME, firestore } from '../common/lib/firebase'

export default (roomId: string, observer: (data: any) => void): Function => {
  return firestore
    .collection(ROOMS_COLLECTION_NAME)
    .doc(roomId)
    .onSnapshot((docSnapshot: any) => {
      const data = docSnapshot.data()
      if (data) {
        observer(data)
      }
    })
}
