import React, { useEffect, useRef, useState } from 'react'

import * as Style from './style'
import { useParams } from 'react-router-dom'
import AttendeeNotes from '../notes'
import useQueryRequest from '../common/hooks/useQueryRequest'
import { getNotes, NoteDto } from '@cloudmeet/web-core/notes/api'
import { getRoomRecordings, RoomRecordingResponse } from '@cloudmeet/web-core/rooms/api'

export default () => {
  /** ------------------------------------ **
   Hooks declaration
   ** ------------------------------------ **/
  const params: any = useParams()
  const roomId = params.id
  const participantKey = new URLSearchParams(location.search).get('participantKey') ?? ''
  const roomKey = new URLSearchParams(location.search).get('roomKey') ?? ''
  const videoRef = useRef(null)

  /** ------------------------------------ **
   Local States
   ** ------------------------------------ **/
  const [videoFileDuration, setVideoFileDuration] = useState(0)
  const [notes, setNotes] = useState<NoteDto[] | null>(null)
  const [roomRecordings, setRoomRecordings] = useState<RoomRecordingResponse | null>({
    duration: 0,
    downloadUrl: '',
  })
  const { sendQueryRequest: sendGetNotesRequest } = useQueryRequest()
  const { sendQueryRequest: sendGetRoomRecordings } = useQueryRequest()

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/
  const onNotBreakpointClick = (note: any) => {}

  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/
  useEffect(() => {
    ;(async () => {
      const recordings = await sendGetRoomRecordings<RoomRecordingResponse>(() => getRoomRecordings(roomId, roomKey))
      setRoomRecordings(recordings)

      if (participantKey) {
        const notes = await sendGetNotesRequest<NoteDto[]>(() =>
          getNotes({
            roomId,
            roomKey,
            participantKey,
            q: '',
          }),
        )

        setNotes(notes)
      }
    })()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!videoRef.current) return

      const video = videoRef.current as any

      if (video.readyState > 0) {
        setVideoFileDuration(video.duration)
        clearInterval(interval)
      }
    }, 200)

    return () => {
      clearInterval(interval)
    }
  }, [])

  /** ------------------------------------ **
   Main Component
   ** ------------------------------------ **/
  return (
    <>
      <div className={Style.container}>
        <div className={Style.viewerContainer} style={{ width: participantKey ? '75%' : '100%' }}>
          {roomRecordings && roomRecordings.downloadUrl && (
            <video
              onTimeUpdate={(e) => {
                // @ts-ignore
                e.target.setAttribute('controls', 'controls')
              }}
              className={Style.videoContainer}
              src={roomRecordings.downloadUrl}
              autoPlay={false}
              controls={true}
              ref={videoRef as any}
            />
          )}

          {participantKey && (
            <div className={Style.notesBreakpointContainer}>
              <div className={Style.notesBreakpointsItems}>
                {videoFileDuration &&
                  notes &&
                  notes.map((c: any) => (
                    <div
                      onClick={() => onNotBreakpointClick(c)}
                      className={Style.notesBreakpointsItem}
                      style={{
                        left: `${calculateNoteBreakpointPosition(videoFileDuration, c.videoSeek)}%`,
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>

        {participantKey && (
          <div className={Style.notesContainer}>
            <AttendeeNotes
              roomId={roomId}
              participantKey={participantKey}
              roomKey={roomKey}
              onNoteClicked={(note: any) => {
                // @ts-ignore
                videoRef.current.currentTime = note.videoSeek
              }}
              showAddNotesForm={false}
            />
          </div>
        )}
      </div>
    </>
  )
}

const calculateNoteBreakpointPosition = (videoFileDuration: number, videoSeek: number) => {
  if (videoFileDuration > 0 && videoSeek) {
    const result = (videoSeek / videoFileDuration) * 100
    return result < 100 ? result : 100
  }
  return 0
}
