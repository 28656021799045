import { css } from '@emotion/css'

export const wrapper = css`
  & .breakout-rooms {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & .breakout-room {
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #d3d9e0;
      margin-bottom: 1rem;

      & .title-wrap {
        padding: 8px 12px;
        border-bottom: 1px solid #d3d9e0;
        display: flex;
        justify-content: space-between;

        & .title {
          font-weight: 600;
          color: #2d3748;
        }

        & .total-participants {
          color: #b1b7c0;
        }
      }

      & .content-wrap {
        padding: 12px;
      }

      & .participant-list-item {
        display: flex;
        align-items: center;
        padding: 4px;
        border: 1px solid #f0f2f5;
        border-radius: 56px;
        margin: 4px 0px;

        & .presenter-list-item {
          background: #f0f5ff;
          border-radius: 32px;
          padding: 1px 8px;
          color: #2fc5d2;
        }

        & .delete-wrap {
          cursor: pointer;

          & i {
            color: #808996;
            font-size: 22px;
          }
        }
      }
    }
  }

  & .move-btn {
    display: none;
    cursor: pointer;
    text-align: center;
    background: #f0f2f5;
    border-radius: 50%;
    padding: 1px;
    width: 25px;
    height: 25px;
    & i {
      color: #b1b7c0;
    }
  }

  & .participant-list-item:hover .move-btn {
    display: block;
  }
`
